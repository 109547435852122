import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getProfileDetails } from "../../store/Profile/action";
import { getMarginOverview } from "../../store/marginOverview/action";
import { getCollateral } from "../../store/Collateral/action";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileIcon from "../../assets/images/logo/profileIcon.png";
import { LOGOUT_APP } from "../../helpers/url_helper";

const ProfileDropdown = () => {
  const history = useNavigate();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const client_type = localStorage.getItem("client_type");
  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));
  const newtype =client_type
  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));
  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));
  const dispatch = useDispatch();
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));
console.log(newtype,'newtype');

  //console.log(profileDetails);
  /*
  get data
  */
  useEffect(() => {
    dispatch(getProfileDetails({ client_code: client_code }));
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getCollateral({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch, client_code, client_token]);

  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserName(
        process.env.REACT_APP_DEFAULTAUTH === "fake"
          ? obj.username === undefined
            ? obj.data.first_name
            : "Admin" || "Admin"
          : process.env.REACT_APP_DEFAULTAUTH === "firebase"
          ? obj.providerData[0].email
          : "Admin"
      );
    }
  }, [userName, user]);

  const goTOFunction = () => {
    history("/profile");
  };

  const logoutHandler = () => {
    //app
  //   localStorage.clear();
  //   if(newtype == "Bonanza" ){
  // window.open( 'https://resourcestest.bigul.co/bonanza/sso-login', "_self");
  //   }
  //   else{
  //     window.open(LOGOUT_APP, "_self");
     
  //   }

    //live

    localStorage.clear();
    if(newtype === "Bonanza" ){
  
      window.open( 'https://resources.bonanzaonline.com/bonanza/sso-login', "_self");
    }
    else{
      window.open(LOGOUT_APP, "_self");
     
    }
  
};
console.log(client_type,'client_type');

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    //console.log(isProfileDropdown);
    setIsProfileDropdown(!isProfileDropdown);
    //e.stopPropagation();
  };
  const amountFormatter = (data, data2) => {
    let Total = 0;
    if (data > 0) {
      Total =  -Math.abs(data)  + data2
      let t = Math.round(Total * 100) / 100
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString()}`;
      }
      console.log(Total);

    } else if (data < 0) {
      ////console.log(data);
      Total = Math.abs(data) + data2
      let t = Math.round(Total * 100) / 100
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString()}`;
      }

    } else {
      Total = data + data2
      let t = Math.round(Total * 100) / 100
      if (t > 0) {
        return `₹${t.toLocaleString("en-IN").toString()}`;
      }else if(t == 0){
        return `₹0`;
      } else {
        return `- ₹${t.toLocaleString("en-IN").toString()}`;
      }
      //console.log(Total);

    }
    //  ////console.log(data);
  };
  
  
  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    if (el.CollateralQuantity > 0) {
      CollateralValues.push((el.CollateralQuantity * el.mktrate) - ((el.CollateralQuantity * el.mktrate* el.Haircut) / 100));
    }
    return el.CollateralQuantity > 0;
  });

  const CollateralValuessubTotal = CollateralValues.reduce(function (acc, val) {
    return acc + val;
  }, 0);
console.log(availableMargin,'availableMargin?.availableMargin[0]?.Final1');

  
  let TotalValue = 0;
  if (
    availableMargin?.availableMargin?.length > 0 &&
    +availableMargin?.availableMargin[0]?.Final1 > 0
  ) {
    TotalValue =
      -Math.abs(+availableMargin?.availableMargin[0]?.Final1) +
      CollateralValuessubTotal;
  } else if (
    availableMargin?.availableMargin?.length > 0 &&
    +availableMargin?.availableMargin[0]?.Final1 < 0
  ) {
    TotalValue =
      Math.abs(+availableMargin?.availableMargin[0]?.Final1) +
      CollateralValuessubTotal;
  } else if (
    availableMargin?.availableMargin?.length > 0 &&
    +availableMargin?.availableMargin[0]?.Final1 === 0
  ) {
    TotalValue =
      +availableMargin?.availableMargin[0]?.Final1 + CollateralValuessubTotal;
  }

  //console.log(isProfileDropdown);
  return (
    <React.Fragment>
      <>
        <Dropdown
          isOpen={isProfileDropdown}
          toggle={toggleProfileDropdown}
          className="ms-sm-3 header-item topbar-user"
        >
          {!isEmpty(profileDetails[0]) && profileDetails[0].LOGO ? (
                  <img
                    src={
                      !isEmpty(profileDetails[0]) && profileDetails[0].LOGO
                        ? profileDetails[0].LOGO
                        : avatar1
                    }
                    className="rounded-circle header-profile-user"
                    alt="user-profile"
                    style={{ border: "1px solid gray" }}
                  />
                ) : (
                  <div
                    className="rounded-circle header-profile-user ms-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid gray",
                    }}
                  >
                    <p
                      style={{
                        margin: "0",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#5367fc",
                      }}
                    >
                      {!isEmpty(profileDetails[0]) &&
                        !profileDetails[0].LOGO &&
                        profileDetails[0].FIBSACCT.charAt(
                          0
                        ).toLocaleUpperCase()}
                    </p>
                  </div>
                )}
                                <span className="text-start ms-xl-2">
                  <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                    {!isEmpty(profileDetails[0]) &&
                      profileDetails[0].FIBSACCT.split(" ")[0]}
                  </span>
                  {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                Founder
              </span> */}
                </span>
          <DropdownToggle tag="button" type="button" className={`btn ${
                      !isProfileDropdown
                        ? "ri-arrow-down-s-line"
                        : "ri-arrow-up-s-line"
                    } text-muted fs-16 align-middle me-1`}>
                      

          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {/* <h6 className="dropdown-header">Welcome {userName}!</h6> */}
            {
              client_type == 'Bigul'&&
              <DropdownItem onClick={goTOFunction}>
              {!isEmpty(profileDetails[0]) && profileDetails[0].LOGO ? (
                <img
                  src={
                    !isEmpty(profileDetails[0]) && profileDetails[0].LOGO
                      ? profileDetails[0].LOGO
                      : avatar1
                  }
                  className="rounded-circle header-profile-user"
                  alt="user-profile"
                  style={{
                    border: "1px solid #05cd99",
                    height: "15px",
                    width: "15px",
                  }}
                />
              ) : (
                <div
                  className="rounded-circle header-profile-user"
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid gray",
                    height: "15px",
                    width: "15px",
                    marginRight: "9px",
                  }}
                >
                  <p
                    style={{
                      margin: "0",
                      fontSize: "10px",
                      fontWeight: "600",
                      color: "#5367fc",
                    }}
                  >
                    {!isEmpty(profileDetails[0]) &&
                      !profileDetails[0].LOGO &&
                      profileDetails[0].FIBSACCT.charAt(0).toLocaleUpperCase()}
                  </p>
                </div>
              )}
              <span className="align-middle">Profile</span>
            </DropdownItem>
            }
           
            {/* <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}>
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Messages</span>
          </DropdownItem> */}
            {/* <DropdownItem href={process.env.PUBLIC_URL + "#"}>
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Taskboard</span>
          </DropdownItem> */}

            <div className="dropdown-divider"></div>
            {/* {
              client_type == 'Bigul'&&
              <DropdownItem href={process.env.PUBLIC_URL + "/overview"}>
              <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">
                Balance :{" "}
                <b
                  className={`text-3 text-color5  mb-1 flex-grow-1 ${
                    
                    TotalValue > 0
                      ? "text-color6"
                      : "text-color11"
                  }`}
                >{amountFormatter(
                  availableMargin?.availableMargin?.length > 0
                    ? availableMargin?.availableMargin[0]?.Final1
                    : 0,
                  CollateralValuessubTotal
                )}</b>
              </span>
            </DropdownItem>
            } */}
          
            {/* <DropdownItem
            href={process.env.PUBLIC_URL + "/pages-profile-settings"}
          >
            <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem> */}
            {/* <DropdownItem
            href={process.env.PUBLIC_URL + "/auth-lockscreen-basic"}
          >
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Lock screen</span>
          </DropdownItem> */}
            <DropdownItem href={client_type =='Bonanza'?'mailto:customercare@bonanzaonline.com':"mailto:support@bigul.co"} target="_blank" >
              <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Help</span>
            </DropdownItem>
            {/* <DropdownItem href={process.env.PUBLIC_URL + "/logout"}> */}
            <DropdownItem onClick={() => {logoutHandler()}}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </>
    </React.Fragment>
  );
};

export default ProfileDropdown;
