//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const PAYMENT_key = "475681656b2d6ed5bcb51d6dd772fa4f";
// for UAT URL
// export const paymentBackApp = 'https://dashboard.bigulapp.in/call_back'
// export const LOGOUT_APP = "https://bigulapp.in/sso-login-middleware";
// export const redirectApp = 'https://dashboard.bigulapp.in/profile'
// export const redirectApp1 = 'https://dashboard.bigulapp.in/family'
// export const NEW_KYC_URL = "https://newkyc.bigulapp.in/";
// export const Payment_url = 'https://payment.bigulapp.in/';
// export const redirect_app = 'https://dashboard.bigulapp.in/dashboard';
// export const Payment_url_Bonanza = 'https://payment.bonanzaonline.com/v1/';

// for LIVE URL
export const Payment_url = 'https://payment.bigul.co/v1/';
export const Payment_url_Bonanza = 'https://payment.bonanzaonline.com/v1/';
export const redirect_app = 'https://dashboard.bigul.co/dashboard';
export const NEW_KYC_URL = "https://kyc.bigul.co/";
export const LOGOUT_APP = "https://bigul.co/sso-login-middleware";
export const redirectApp = 'https://dashboard.bigul.co/profile';
export const redirectApp1 = 'https://dashboard.bigul.co/family';
export const paymentBackApp = 'https://dashboard.bigul.co/call_back';

const Dashboard_api = "https://client-dasboardapis.bigul.co/api/";
export const MOD_EKYC_NEW = NEW_KYC_URL + 'modification';
export const MOD_STATUS_NEW = MOD_EKYC_NEW + '/check-modification-status';
export const MOD_ESIGN = MOD_EKYC_NEW + '/get-esign-my-etrade';
export const MOD_SEND_MOBILE_NEW = MOD_EKYC_NEW + '/send-mobile-otp';
export const GET_PROFILE_NEW = MOD_EKYC_NEW + '/get-customer-info?enc=';

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

const MOD_EKYC = "https://dkyc.bonanzaonline.com/mod_ekyc/";
const Bonanza_API = "https://clientlevel.bonanzaonline.com:3031/Bonanza_API/CLSqlInterface.svc/";
const BIGULAPP_URL = "https://bigul.co/api/";
const BIGULAPP_URL_live = "https://bigul.co/api/";
export const COLATRAL_NEW_API = BIGULAPP_URL + "collateral_api_enc"
export const PAYOUT_NEW_API = BIGULAPP_URL + "payout_api_enc"
export const BALANCE_NEW_API = 'https://payment.bigul.co/api/payment_cashbalance'
export const BALANCE_NEW_API_BONANZA = 'https://bigul.co/api/get-cash-balance'

export const COLATRAL_NEW_API_BONANZA = BIGULAPP_URL + "get-collateral-amount"


// refer & eran using
export const POST_REFERRAL_LINK = "https://bigul.co/api/";
const DKYC_BONANZA_API = "https://apinew.bigul.co:4040/api/v1/";
const DKYC_BONANZA_API_REFER = "https://apinew.bigul.app:4040/api/v1/";
const BIGUL_PAY_URL = "http://14.141.71.10:9494/bigulpay/";
const BONANZA_API = "http://124.153.94.35:3030/Bonanza_API/CLSqlInterface.svc/";
export const POST_TOTAL_REFERRED = "https://apinew.bigul.co:4040/api/v1/" + "getTotalReferral";
export const GET_TRADE_REFER_SUMMARY = "http://14.141.71.10:9494/bigulpay/" + "paymentapi_uat.php/";

// algo Transtion algo list
const Algo_transation_Api = "https://client-dasboardapis.bigul.co/api/get-transaction-list?ctcl_id=";
const Algo_list = "https://client-dasboardapis.bigul.co/api/get-algo-list?";
const AlgoStatusCHeck = Dashboard_api + "is-algo-client?";
//GENERATE KEY
export const GET_GENERATE_KEY = BIGULAPP_URL + 'yardbroking?prodco=broking&firmco=BON0001';
//GENERATE KEY
export const mod_mobile_check = 'https://dkyc.bonanzaonline.com/directkycapi/index.php';
//GENERATE KEY
export const mod_email_check = 'https://dkyc.bonanzaonline.com/ekycapi/checkEmailClient';
//ENCRYPT KEY
export const GET_ENCRYPT_KEY = Bonanza_API + 'Encryptclcode?';
//NOTIFICATION
export const GET_NOTIFICATIONS_LIST = Dashboard_api + 'notification-list?';
//NOTIFICATION
export const mod_mail_send = Dashboard_api + 'mail_sms_modification';
export const mod_update_iscomplete_send = MOD_EKYC + 'updateProfileModification';
// E Learning
export const GET_ELEARNINGVIDEOS_LIST = Dashboard_api + 'e-learning-list?';
// E Learning Category
export const GET_ELEARNINGVIDEOS_CATEGORY_LIST = Dashboard_api + 'e-learning-category-list?';
// E Learning by Category
export const GET_ELEARNINGVIDEOS_BY_CATEGORY_LIST = Dashboard_api + 'e-learning-list?';
// ProfileDetails
export const GET_PROFILE_DETAILS = BIGULAPP_URL + 'profile_details_enc';
// Bigul Highlights
export const GET_HIGHLIGHTS_DETAILS = Dashboard_api + 'highlights-feature-list?';
// Dashboard Suggested Algos
export const GET_SUGGESTED_ALGO = Dashboard_api + 'suggested-algos-list?';
// Dashboard Invested Summary
export const GET_INVESTED_SUMMARY = Bonanza_API + 'portfoliovalue?';
// Dashboard Add On
export const GET_Add_On = Dashboard_api + 'add-ons-feature-list?';
// Dashboard Feature And Plan
export const GET_FEATURE_AND_PLAN = Dashboard_api + 'active-and-suggested-plans?';
// My Plan
export const GET_MY_PLAN = Dashboard_api + 'active-and-suggested-plans?';
// My Plan Duration
export const GET_MY_PLAN_DURATION = Dashboard_api + 'subscription-plan-list?';
// My Feature Plan
export const GET_MY_FEATURE_PLAN = Dashboard_api + 'client-feature-list?';
// MY_FEATURE_SUGGESTED_PLAN 
export const GET_MY_FEATURE_SUGGESTED_PLAN = Dashboard_api + 'all-feature-list?';
// TRADE_BOOK_CASH
export const GET_TRADE_BOOK_CASH = Bonanza_API + 'glcaptr?';
export const CHECKDIGIO_STATUS = MOD_EKYC_NEW + '/check-digio-status'
export const OPEN_DIGILOKCKER = MOD_EKYC_NEW + '/get-digio-link'
export const OPEN_DIGILOKCKER_FAMILY = MOD_EKYC_NEW + '/family-get-digio-link'
export const DIGIO_STATUS_FAMILY = MOD_EKYC_NEW + '/family-check-digio-status'
export const DIGIO_FAMILY_SUBMITION = MOD_EKYC_NEW + '/family-update-request'
// TRADE_BOOK_DERIVATIVE
export const GET_TRADE_BOOK_DERIVATIVE = Bonanza_API + 'gldertr?';
// LEDGER
export const GET_LEDGER = Bonanza_API + 'fin?';
// P_AND_L_CASH
export const GET_P_AND_L_CASH = Bonanza_API + 'glcap?';
export const ESIGN_PAYMENT = 'https://bigul.co/api/encPaymentUrl';
// P_AND_L_DERIVATIVE
export const GET_P_AND_L_DERIVATIVE = Bonanza_API + 'glder?';
// P_AND_L_DERIVATIVE_2
export const P_AND_L_DERIVATIVE_2 = Bonanza_API + 'glder?';
// HOLDING
export const GET_HOLDING = Bonanza_API + 'holdwithavg?';
// TAX_REPORT 
export const GET_TAX_REPORT = Bonanza_API + 'ltstsummerizedvalue?';
// GET_AVAILABLE_MARGIN 
export const GET_AVAILABLE_MARGIN = Bonanza_API + 'payreqbalance?';
// GET_BANK_DETAIlS
export const GET_BANK_DETAIlS = Bonanza_API + 'payreqbank?';
// GET_WITHDRAW
export const GET_WITHDRAW = 'https://bigul.co/api/withdrawal_amount_enc?data=';
// GET_MY_INVESTED_ALGO_TOP
export const GET_MY_INVESTED_ALGO_TOP = Dashboard_api + 'algo/get-algo-details';
// GET_MY_INVESTED_ALGO_GRAPH
export const GET_MY_INVESTED_ALGO_GRAPH = Dashboard_api + 'algo/getchart';
// GET_CURRENT_OVERVIEW_PMS
export const GET_CURRENT_OVERVIEW_PMS = 'https://api.bonanzawealth.com/api/v1/bigul/pms/data';
// GET_CURRENT_OVERVIEW_MF
export const GET_CURRENT_OVERVIEW_MF = 'https://api.bonanzawealth.com/api/v1/bigul/mf/data';
//  COLLATERAL_GET
export const COLLATERAL_GET = Bonanza_API + 'rmsholding_symp?';
// GET_CURRENT_OVERVIEW_SA
export const GET_CURRENT_OVERVIEW_SA = 'https://api.bonanzawealth.com/api/v1/bigul/sa/data';
// GET_GET_CART_LIST
export const GET_GET_CART_LIST = Dashboard_api + 'get-cart-list';
// ADD_ITEM_TO_CART
export const ADD_ITEM_TO_CART = Dashboard_api + 'cart-store';
// REMOVE_ITEM_TO_CART
export const REMOVE_ITEM_TO_CART = Dashboard_api + 'remove-cart-item';
// UPDATE_ITEM_TO_CART
export const UPDATE_ITEM_TO_CART = Dashboard_api + 'cart-update/';
// GET_PAYMENT_DETAILS
export const GET_PAYMENT_DETAILS = Dashboard_api + 'payment-details';
// GET_SUMMARY
export const GET_SUMMARY = Dashboard_api + 'algo/getsammary';
// GET_PLAN_HISTORY
export const GET_PLAN_HISTORY = Dashboard_api + 'plan-history?';
// get algo transtion
export const GET_ALGO_TRANSATIONS = Algo_transation_Api;
export const GET_ALGO_LIST = Algo_list + "client_code=";
export const GET_AlgoStatusCHeck = AlgoStatusCHeck;

export const Algo_Dashboard = "https://client-dasboardapis.bigul.co/api/get-dashboard-data?client_code=";
export const algo_PL_List = "https://client-dasboardapis.bigul.co/api/get-pnl-list?ctcl_id=";
//SUBMIT OTP
export const SUBMIT_OTP = Dashboard_api + 'validate-mobile-otp';
//RESEND OTP
export const RESEND_OTP = Dashboard_api + 'resend-otp';
// export const GET_AlgoStatusCHeck= AlgoStatusCHeck + "client_code=";
//SEND OTP
export const SEND_OTP = Dashboard_api + 'payment-store';
//SEND_BANK
export const SEND_BANK = Dashboard_api + 'payment-store';
//CALL_BACK
export const CALL_BACK = Dashboard_api + 'call-back-url';
//DOWNLOAD_MARGIN
export const DOWNLOAD_MARGIN = Bonanza_API + 'fin?';
//LATEST UPDATE ON
export const LATEST_UPDATE_ON = Dashboard_api + 'last-payment';
//LATEST Notification Update ON
export const NOTIFICATION_COUNT_REMOVE = Dashboard_api + 'notification-list-update';

//LEDGER_API
export const SYMPHONY_WITHDRAWAL_API = BIGULAPP_URL_live + 'symphony-get-balance-enc';

export const LEDGER_API = Bonanza_API + 'fin?';
//MARGIN_API
export const MARGIN_API = Bonanza_API + 'fin?';
//COLLATERAL_API
export const COLLATERAL_API = Bonanza_API + 'rmsholding_symp?';
//SELECTED_PLAN
export const ELECTED_PLAN = Dashboard_api + 'plan-details/';
//SELECTED_FEATURE
export const SELECTED_FEATURE = Dashboard_api + 'feature-details/';
//REFER_AND_EARN
export const REFER_AND_EARN = BIGULAPP_URL + 'generate-referral-link-enc';
//PMS_DOWNLOAD
export const PMS_DOWNLOAD = 'https://api.bonanzawealth.com/api/v1/bigul/pms/reports';
//TAX_REPORT_LIST_GET
export const TAX_REPORT_LIST_GET = Dashboard_api + 'tax-report-list?';
//TAX_DETAILS_GET
export const TAX_DETAILS_GET = Dashboard_api + 'tax-report-details/';
//TAX_REPORT_GET
export const TAX_REPORT_GET = Bonanza_API + 'ltstsummerizedvalue?';
//CONTRACT_NOTE_MONTHLY
export const CONTRACT_NOTE_MONTHLY = Bonanza_API + 'contractnew?';
//CONTRACT_NOTE_DOWNLOAD
export const CONTRACT_NOTE_DOWNLOAD = Bonanza_API + 'DownloadFile?';
// MOBILE_NO_UPDATE
export const MOBILE_NO_UPDATE = MOD_EKYC + 'mobile-number-update';
export const SEND_OTP_MODI_NEW = MOD_EKYC_NEW + '/send-email-otp';
export const VERIFY_OTP_MODI_NEW = MOD_EKYC_NEW + '/verify-email-otp';
export const MOBILE_UPDATE_NEW = MOD_EKYC_NEW + '/update-modification';
// GENERATE_OTP
export const GENERATE_OTP = MOD_EKYC + 'send-otp-modification';
export const GENERATE_OTP_NEW = MOD_EKYC_NEW + '/send-mobile-otp';
// MODIFICATION_PROFILE
export const MODIFICATION_PROFILE = MOD_EKYC + 'ClientCpyLD2DKYCCheck';
// VERIFY_OTP
export const VERIFY_OTP = MOD_EKYC + 'verify-otp-modification';
export const VERIFY_OTP_NEW = MOD_EKYC_NEW + '/verify-mobile-otp';
// RESEND_M_OTP
export const RESEND_M_OTP = MOD_EKYC + 'resend-otp-modification';
// PENNY_DROP
// export const PENNY_DROP = 'https://api.digio.in/client/verify/bank_account';
export const PENNY_DROP = 'https://bigul.co/api/verify-bank-account';
// MOD_STATUS
export const MOD_STATUS = MOD_EKYC + 'check-modification-status';
// MOD_STATUS_ALL
export const MOD_STATUS_ALL = MOD_EKYC + 'check-modification-status-all';
// DP_SHAME_LIST
export const DP_SHAME_LIST = MOD_EKYC + 'GetDPSchemes';
// BANK_MASTER_INFO
export const BANK_MASTER_INFO = 'https://apinew.bigul.app:4040/api/v1/getBankMasterDataByIfsc';
// BANK_MASTER_INFO2
export const BANK_MASTER_INFO2 = 'https://ifsc.razorpay.com/';
// PROFILE_BUSINESS_INFO
export const PROFILE_BUSINESS_INFO = MOD_EKYC + 'business-info';
// PROFILE_NOMINEE_INFO1
export const PROFILE_NOMINEE_INFO1 = BIGULAPP_URL + 'nominee_cdsl_enc';
// PROFILE_NOMINEE_INFO2
export const PROFILE_NOMINEE_INFO2 = BIGULAPP_URL + 'nominee_nsdl_enc';
// PLEDGE_UPDATE
export const PLEDGE_UPDATE = Bonanza_API + 'updpoamargpled?';
// EMAIL_UPDATE
export const EMAIL_UPDATE = MOD_EKYC + 'email-update';
// DDPI_UPDATE
export const DDPI_UPDATE = MOD_EKYC + 'ddpi-update';
// DDPI_ESIGN
export const DDPI_ESIGN = 'https://dkyc.bonanzaonline.com/ekycapi/GetESignMyetradeDdpi';
// AFTER_E_SIGN
export const AFTER_E_SIGN = MOD_EKYC + 'modification-payment';
// ALL_ESIGN
export const ALL_ESIGN = MOD_EKYC + 'GetESignModification';
// DP_SCHEME_UPDATE
export const DP_SCHEME_UPDATE = MOD_EKYC + 'dp-scheme-update';
// PASSWORD_UPDATE
export const PASSWORD_UPDATE = MOD_EKYC + 'change-password';
// MPIN_UPDATE
export const MPIN_UPDATE = MOD_EKYC + 'change-mpin';
// SEGMENT_ACTIVATION
export const SEGMENT_ACTIVATION = MOD_EKYC + 'segments-update';
// ADD_NOMINEE
export const ADD_NOMINEE = MOD_EKYC + 'add-nominee';
// ADD_BANK
export const ADD_BANK = MOD_EKYC + 'bank-details';
// BANK_MOD
export const BANK_MOD = MOD_EKYC + 'bank-toggle';
// VERIFY
export const VERIFY = 'https://bigulint.bigul.co/middleware/api/v2/GetProfile';
// GET_CLIENT_ID
export const GET_CLIENT_ID = MOD_EKYC + 'get-client-id';
//PLEDGE
export const PLEDGE = Bonanza_API + 'poamargpled?';

// Calendar
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const GET_UPCOMMINGEVENT = "/upcommingevents";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

// Chat
export const GET_DIRECT_CONTACT = "/chat";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "add/message";
export const GET_CHANNELS = "/channels";
export const DELETE_MESSAGE = "delete/message";

//Mailbox
export const GET_MAIL_DETAILS = "/mail";
export const DELETE_MAIL = "/delete/mail";

// Ecommerce
// Product
export const GET_PRODUCTS = "/apps/product";
export const DELETE_PRODUCT = "/apps/product";
export const ADD_NEW_PRODUCT = "/apps/product";
export const UPDATE_PRODUCT = "/apps/product";

// Orders
export const GET_ORDERS = "/apps/order";
export const ADD_NEW_ORDER = "/apps/order";
export const UPDATE_ORDER = "/apps/order";
export const DELETE_ORDER = "/apps/order";

// Customers
export const GET_CUSTOMERS = "/apps/customer";
export const ADD_NEW_CUSTOMER = "/apps/customer";
export const UPDATE_CUSTOMER = "/apps/customer";
export const DELETE_CUSTOMER = "/apps/customer";

// Sellers
export const GET_SELLERS = "/sellers";

// Project list
export const GET_PROJECT_LIST = "/project/list";

// Task
export const GET_TASK_LIST = "/apps/task";
export const ADD_NEW_TASK = "/apps/task";
export const UPDATE_TASK = "/apps/task";
export const DELETE_TASK = "/apps/task";

// CRM
// Conatct
export const GET_CONTACTS = "/apps/contact";
export const ADD_NEW_CONTACT = "/apps/contact";
export const UPDATE_CONTACT = "/apps/contact";
export const DELETE_CONTACT = "/apps/contact";

// Companies
export const GET_COMPANIES = "/apps/company";
export const ADD_NEW_COMPANIES = "/apps/company";
export const UPDATE_COMPANIES = "/apps/company";
export const DELETE_COMPANIES = "/apps/company";

// Lead
export const GET_LEADS = "/apps/lead";
export const ADD_NEW_LEAD = "/apps/lead";
export const UPDATE_LEAD = "/apps/lead";
export const DELETE_LEAD = "/apps/lead";

// Deals
export const GET_DEALS = "/deals";

// Crypto
export const GET_TRANSACTION_LIST = "/transaction-list";
export const GET_ORDRER_LIST = "/order-list";

// Invoice
export const GET_INVOICES = "/apps/invoice";
export const ADD_NEW_INVOICE = "/apps/invoice";
export const UPDATE_INVOICE = "/apps/invoice";
export const DELETE_INVOICE = "/apps/invoice";

// TicketsList
export const GET_TICKETS_LIST = "/apps/ticket";
export const ADD_NEW_TICKET = "/apps/ticket";
export const UPDATE_TICKET = "/apps/ticket";
export const DELETE_TICKET = "/apps/ticket";

// Dashboard Analytics

// Sessions by Countries
export const GET_ALL_DATA = "/all-data";
export const GET_HALFYEARLY_DATA = "/halfyearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

// Audiences Metrics
export const GET_ALLAUDIENCESMETRICS_DATA = "/allAudiencesMetrics-data";
export const GET_MONTHLYAUDIENCESMETRICS_DATA = "/monthlyAudiencesMetrics-data";
export const GET_HALFYEARLYAUDIENCESMETRICS_DATA = "/halfyearlyAudiencesMetrics-data";
export const GET_YEARLYAUDIENCESMETRICS_DATA = "/yearlyAudiencesMetrics-data";

// Users by Device
export const GET_TODAYDEVICE_DATA = "/todayDevice-data";
export const GET_LASTWEEKDEVICE_DATA = "/lastWeekDevice-data";
export const GET_LASTMONTHDEVICE_DATA = "/lastMonthDevice-data";
export const GET_CURRENTYEARDEVICE_DATA = "/currentYearDevice-data";

// Audiences Sessions by Country
export const GET_TODAYSESSION_DATA = "/todaySession-data";
export const GET_LASTWEEKSESSION_DATA = "/lastWeekSession-data";
export const GET_LASTMONTHSESSION_DATA = "/lastMonthSession-data";
export const GET_CURRENTYEARSESSION_DATA = "/currentYearSession-data";

// Dashboard CRM

// Balance Overview
export const GET_TODAYBALANCE_DATA = "/todayBalance-data";
export const GET_LASTWEEKBALANCE_DATA = "/lastWeekBalance-data";
export const GET_LASTMONTHBALANCE_DATA = "/lastMonthBalance-data";
export const GET_CURRENTYEARBALANCE_DATA = "/currentYearBalance-data";

// Deal type
export const GET_TODAYDEAL_DATA = "/todayDeal-data";
export const GET_WEEKLYDEAL_DATA = "/weeklyDeal-data";
export const GET_MONTHLYDEAL_DATA = "/monthlyDeal-data";
export const GET_YEARLYDEAL_DATA = "/yearlyDeal-data";

// Sales Forecast

export const GET_OCTSALES_DATA = "/octSales-data";
export const GET_NOVSALES_DATA = "/novSales-data";
export const GET_DECSALES_DATA = "/decSales-data";
export const GET_JANSALES_DATA = "/janSales-data";

// Dashboard Ecommerce
// Revenue
export const GET_ALLREVENUE_DATA = "/allRevenue-data";
export const GET_MONTHREVENUE_DATA = "/monthRevenue-data";
export const GET_HALFYEARREVENUE_DATA = "/halfYearRevenue-data";
export const GET_YEARREVENUE_DATA = "/yearRevenue-data";

// Dashboard Crypto
// Portfolio
export const GET_BTCPORTFOLIO_DATA = "/btcPortfolio-data";
export const GET_USDPORTFOLIO_DATA = "/usdPortfolio-data";
export const GET_EUROPORTFOLIO_DATA = "/euroPortfolio-data";

// Market Graph
export const GET_ALLMARKETDATA_DATA = "/allMarket-data";
export const GET_YEARMARKET_DATA = "/yearMarket-data";
export const GET_MONTHMARKET_DATA = "/monthMarket-data";
export const GET_WEEKMARKET_DATA = "/weekMarket-data";
export const GET_HOURMARKET_DATA = "/hourMarket-data";

// Dashboard Crypto
// Project Overview
export const GET_ALLPROJECT_DATA = "/allProject-data";
export const GET_MONTHPROJECT_DATA = "/monthProject-data";
export const GET_HALFYEARPROJECT_DATA = "/halfYearProject-data";
export const GET_YEARPROJECT_DATA = "/yearProject-data";

// Project Status
export const GET_ALLPROJECTSTATUS_DATA = "/allProjectStatus-data";
export const GET_WEEKPROJECTSTATUS_DATA = "/weekProjectStatus-data";
export const GET_MONTHPROJECTSTATUS_DATA = "/monthProjectStatus-data";
export const GET_QUARTERPROJECTSTATUS_DATA = "/quarterProjectStatus-data";

// Dashboard NFT
// Marketplace
export const GET_ALLMARKETPLACE_DATA = "/allMarketplace-data";
export const GET_MONTHMARKETPLACE_DATA = "/monthMarketplace-data";
export const GET_HALFYEARMARKETPLACE_DATA = "/halfYearMarketplace-data";
export const GET_YEARMARKETPLACE_DATA = "/yearMarketplace-data";

// Project
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

// Pages > Team
export const GET_TEAMDATA = "/teamData";
export const DELETE_TEAMDATA = "/delete/teamData";
export const ADD_NEW_TEAMDATA = "/add/teamData";
export const UPDATE_TEAMDATA = "/update/teamData";

// File Manager
// Folder
export const GET_FOLDERS = "/folder";
export const DELETE_FOLDER = "/delete/folder";
export const ADD_NEW_FOLDER = "/add/folder";
export const UPDATE_FOLDER = "/update/folder";

// File
export const GET_FILES = "/file";
export const DELETE_FILE = "/delete/file";
export const ADD_NEW_FILE = "/add/file";
export const UPDATE_FILE = "/update/file";

// To do
export const GET_TODOS = "/todo";
export const DELETE_TODO = "/delete/todo";
export const ADD_NEW_TODO = "/add/todo";
export const UPDATE_TODO = "/update/todo";

// To do Project
export const GET_PROJECTS = "/projects";
export const ADD_NEW_TODO_PROJECT = "/add/project";

//JOB APPLICATION
export const GET_APPLICATION_LIST = "/application-list";

//JOB APPLICATION
export const GET_API_KEY = "/api-key";
//refer and earn
export const GET_API_KEY_ONE = "http://13.235.88.128/yard/cisauth.svc/genapikey";
export const GET_ENCRYPTED_CODE = BONANZA_API + "Encryptclcode";
export const POST_ACC_OPENING_PROCESS =
  DKYC_BONANZA_API_REFER + "getInProcessAccount";
export const POST_ADD_REFERAL_NUMBER = "https://kyc.bigul.co/api/v1/ClientDashboardReferral"
export const POST_GET_REFERRAL_EARNING = 'https://apinew.bigul.app:4040/api/v1/' + 'getReferralEarning'
