// import "./Header.css";
// import "../../Components/referandEarnComponent/ToggleButton"
// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import refferErarnImg from "../../assets/images/referandEarn/dashboard/refferErarnImg.svg"
// import { getRefferlLInkCount } from "../../store/ReferralLink/action";
// import { ImagePath } from "../../helpers/ImagePaths";
// import { getaddRefferlNumber, resetReferralState } from "../../store/AddReferal/action"
// import Share from "../../Components/CustomComponents/Models/share/Share";
// import Loader from "../../Components/Common/Loader";
// import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
// import axios from "axios";
// // import { unCodeUser } from "../../helpers/helpingMethoads";

// const Dashboard = () => {
//   const dispatch = useDispatch();
//   const inputRef = useRef(null);

//   const [mobileNumber, setMobileNumber] = useState("");
//   const [responseData, setResponseData] = useState("");
//   const client_code = localStorage.getItem("client_id");
//   const client_token = localStorage.getItem("client_token");
//   const [termsChecked, setTermsChecked] = useState(false);
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [addMobile, setAddMobile] = useState('');
//   // const unCodeUser = client_code
//   const unCodeUser = "Y09019428"
//   useEffect(() => {
//     const durationPass = "one_year"
//     dispatch(
//       getRefferlLInkCount({
//         code: unCodeUser,
//         referred_by: "CLIENT"
//       })
//     );

//     getTotalEarningShow({ durationPass })
//   }, []);

//   const refferalData = useSelector((state) => ({
//     refferalData: state?.refferalData?.refferalData,
//   }));

//   const { AddReferalNumber } = useSelector((state) => ({
//     AddReferalNumber: state?.AddReferalNumber,
//   }));
//   useEffect(() => {
//     setAddMobile(AddReferalNumber?.AddReferalNumber?.data?.message);
//   }, [AddReferalNumber])




//   const addMobileNumber = () => {
//     setAddMobile('');
//     dispatch(resetReferralState());
//     const durationPass = "one_year"
//     if (!mobileNumber) {
//       setError("Please enter a 10-digit mobile number to proceed.");
//       return;
//     }
//     if (mobileNumber.length !== 10) {
//       setError('Please ensure your mobile number is exactly 10-digits.');
//       return;
//     }
//     if (!termsChecked) {
//       setError("Please accept the terms and conditions to proceed.");
//       return;
//     }
//     dispatch(
//       getaddRefferlNumber({
//         mobile: mobileNumber,
//         refcode: unCodeUser,
//         refname: "bigul",
//         mx_Source_URL: "https://bigul.co",
//       })
//     );
//     setLoading(true)
//     setError("")
//     getTotalEarningShow({ durationPass })
//   }

//   const handleInputChange = (event) => {
//     const inputValue = event.target.value;

//   if (/^1/.test(inputValue)) {
//     setError("Please enter a valid mobile number");
//     return;
//   }
//   if (/^2/.test(inputValue)) {
//     setError("Please enter a valid mobile number");
//     return;
//   }
//   if (/^3/.test(inputValue)) {
//     setError("Please enter a valid mobile number");
//     return;
//   }
//   if (/^4/.test(inputValue)) {
//     setError("Please enter a valid mobile number");
//     return;
//   }
//   if (/^5/.test(inputValue)) {
//     setError("Please enter a valid mobile number");
//     return;
//   }

//   // const inputValueOne = inputValue.replace(/\D/g, '');
//   const inputCheck=  inputValue.replace(/[@#$\-+=]/);
//  const  inputValueTwo = inputCheck.slice(0, 10);

//   setMobileNumber(inputValueTwo);
//   setLoading(false);
//   setError("");
//   };

//   const handleCheckboxChange = () => {
//     if (mobileNumber == "") {
//       setError("Please enter mobile number first")
//       return
//     }
//     setTermsChecked(!termsChecked);
//   };

//   const getTotalEarningShow = async ({ durationPass }) => {
//     try {
//       const apiUrl = 'https://apinew.bigul.app:4040/api/v1/getReferralEarning';
//       const payload = {
//         duration: durationPass,
//         refcode: unCodeUser,
//       };

//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(payload),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const data = await response.json();
//       setResponseData(data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const copyToClipboard = () => {
//     inputRef.current.select();
//     document.execCommand('copy');
//   };



//   return (
//     <div
//       className="main_wr pt-2"
//     >
//       <div className="d-flex justify-content-center"></div>
//       <div className="container refer-earn-container">
//         <div className="row main_body_wr">
//           <div className="col-md-8">
//             <div className="my_referral_wr">
//               <div className="d-flex align-items-center">
//                 <div className="my_refferal_icon_wr d-flex">
//                   <img src={ImagePath?.myRefferalIcon} alt="my refferal" />
//                 </div>
//                 <span className="my_refferal_text">My Referrals</span>
//               </div>

//               <div className="my_reff_child_wr d-flex">
//                 <div className="my_reff_first_child ">
//                   <span className="acc_process_heading_text">
//                     Total Referred
//                   </span>
//                   <h5 className="acc_process_heading_value">
//                     {responseData == "" ? 0 : parseFloat(responseData?.data?.AllRefer)}
//                   </h5>
//                 </div>
//                 <div className="my_reff_sec_child ">
//                   <div className="acc_in_process ">
//                     <span className="acc_process_heading_text">A/c Opened</span>
//                     <h5 className="acc_process_heading_value">
//                       {responseData == "" ? 0 : parseInt(responseData?.data?.AllOpened)}
//                     </h5>
//                   </div>
//                 </div>
//               </div>

//               <div className="my_reff_child_wr d-flex">
//                 <div className="my_reff_first_child ">
//                   <span className="acc_process_heading_text">Traded Users</span>
//                   <h5 className="acc_process_heading_value">
//                     {responseData == "" ? 0 : parseInt(responseData?.data?.total_account_traded)}
//                   </h5>
//                 </div>
//                 <div className="my_reff_sec_child ">
//                   <div className="acc_in_process ">
//                     <span className="acc_process_heading_text">
//                       A/c Opening In process
//                     </span>
//                     <h5 className="acc_process_heading_value">
//                       {responseData == "" ? 0 : parseInt(responseData?.data?.AllInProgress)}
//                     </h5>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="my_earnings_wr">
//               <div className="d-flex my_earning_header">
//                 <div className="d-flex align-items-center">
//                   <div className="my_refferal_icon_wr d-flex">
//                     <img src={ImagePath?.myRefferalIcon} alt="my refferal" />
//                   </div>
//                   <span className="my_refferal_text">My Earnings</span>
//                 </div>
//               </div>

//               <div className="earnings_items_wr pt-5 d-flex">
//                 <div className="earnign_item_item_wr">
//                   <div className="image_wr">
//                     <img
//                       src={ImagePath?.totalEarningIcon}
//                       alt="total earning"
//                     />
//                   </div>
//                   <div className="earning_text">
//                     <span>Total Earning</span>
//                   </div>
//                   <h5>
//                     ₹
//                     {responseData == "" ? 0 : parseFloat(responseData?.data?.total_earning?.replace(/,/g, ''))?.toLocaleString("en-IN")
//                     }
//                   </h5>
//                 </div>

//                 <div className="line" />

//                 <div className="earnign_item_item_wr">
//                   <div className="image_wr">
//                     <img
//                       src={ImagePath?.amountCreditLedgreIcon}
//                       alt="total earning"
//                     />
//                   </div>
//                   <div className="earning_text">
//                     <span>Account Opening Income</span>
//                   </div>
//                   <h5>
//                     ₹ {responseData == "" ? 0 : parseFloat(responseData?.data?.totalAcountEarning?.replace(/,/g, ''))?.toLocaleString("en-IN")
//                     }
//                   </h5>
//                 </div>

//                 <div className="line" />

//                 <div className="earnign_item_item_wr">
//                   <div className="image_wr">
//                     <img
//                       src={ImagePath?.remainingBalanceIcon}
//                       alt="total earning"
//                     />
//                   </div>
//                   <div className="earning_text">
//                     <span>Brokerage income</span>
//                   </div>
//                   <h5>
//                     ₹   {responseData == "" ? 0 :
//                       parseFloat(responseData?.data?.total_brok_earning?.replace(/,/g, ''))?.toLocaleString("en-IN")
//                     }
//                   </h5>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-md-4">
//             <div className="add_referral_wr h-100">
//               <div className="d-flex align-items-center">
//                 <div className="my_refferal_icon_wr d-flex">
//                   <img src={ImagePath?.addRefferalIcon} alt="my refferal" />
//                 </div>
//                 <span className="my_refferal_text">Add a Referral</span>
//               </div>
//               <div className="form_fields_wr mt-3">
//                 <span>Refer friends, family, networks to earn reward!</span>
//                 <p className="p-label">Mobile Number</p>
//                 <input
//                   className="inpute_field_wr"
//                   type="number"
//                   placeholder="Enter a mobile number"
//                   value={mobileNumber}
//                   style={{ color: "black" }}
//                   maxLength="10"
//                   onChange={handleInputChange}
//                   onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

//                 />
//               </div>

//               {error && <p style={{ color: "red", fontSize: '12px' }}>{error}</p>}
//               <div className="term_conditon_wr d-flex flex-column align-items-start pt-3 pb-3">
//                 <label>
//                   <input
//                     className="mt-1"
//                     type="checkbox"
//                     id="termsCheckbox"
//                     checked={termsChecked}
//                     onChange={handleCheckboxChange}
//                   />
//                   &nbsp;
//                   I Agree to the referral scheme
//                   <a
//                     href="https://bigul.co/terms-and-conditions"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="term_condition_blue"
//                   >
//                     &nbsp;Terms & Conditions.
//                   </a>
//                 </label>



//                 {loading == true ? (
//                   <p style={AddReferalNumber?.AddReferalNumber?.success == true ? {color:'green'}:{ color: "red" }}>
//                     {addMobile}
//                   </p>
//                 ) : null}
//               </div>


//               <div className="invite_button_wr d-flex justify-content-center">
//                 <div
//                   className="invite_button"
//                   onClick={() => { loading == false ? addMobileNumber() : null }}
//                 >
//                   <span>Submit</span>
//                 </div>
//               </div>

//               <div className="text-center">
//                 <h5>OR</h5>
//               </div>


//               <div className="referral-link-box mt-4">
//                 <span className="d-block">Share this referral link with friends, family, network!</span>
//                 <div className="referral-link-input">
//                   <input
//                   ref={inputRef}
//                     className="inpute_field_wr"
//                     type="inpute"
//                     value={refferalData?.refferalData && refferalData?.refferalData?.link}
//                   />
//                   <img src={ImagePath?.copy_image} alt="my refferal" className="copy-icon" style={{ cursor: 'pointer' }}   onClick={copyToClipboard} />

//                 </div>

//               </div>

//               {/* <Share data={refferalData?.refferalData && refferalData?.refferalData?.link} style={{}} /> */}
//               <div className="text-center" style={{ marginTop: "10px" }}>
//                 <FacebookShareButton
//                   className="Demo__some-network__share-button me-2"
//                   url={refferalData?.refferalData?.link || ""}
//                   quote={`Guess what? I've discovered the ultimate trading platform—Bigul! It's mind-blowing, with features like Algo Trading on mobile, Trade from Chart, and Basket orders. Join now using my referral link ${refferalData?.refferalData && refferalData?.refferalData?.link || ""} for incredible rewards. Let's dive in and start trading with Bigul today!`}
//                 >
//                   <FacebookIcon size={32} round style={{ margin: '0 7px' }} />
//                 </FacebookShareButton>
//                 <WhatsappShareButton
//                   url={`Guess what? I've discovered the ultimate trading platform—Bigul! It's mind-blowing, with features like Algo Trading on mobile, Trade from Chart, and Basket orders. Join now using my referral link ${refferalData?.refferalData && refferalData?.refferalData?.link || ""} for incredible rewards. Let's dive in and start trading with Bigul today!`}
//                   quote="link"
//                   className="Demo__some-network__share-button me-2"
//                 >
//                   <WhatsappIcon size={32} round style={{ margin: '0 7px' }} />
//                 </WhatsappShareButton>
//                 <EmailShareButton
//                   url={`
//             Hi, How are you doing?
//             You know, I've started using this amazing smart trading platform, Bigul, and it's truly a game changer. It has some really simple and customised features to trade for everything, from Algo Trading 
//             to Options Trading to Mutual Funds.
//             And, the best part is- if you join via my referral link ${refferalData?.refferalData && refferalData?.refferalData?.link || ""}, we both get fantastic rewards!
//             I think it is a must-try.
//             Let's embark on this trading journey together on Bigul.`}
//                   quote="link"
//                   className="Demo__some-network__share-button me-2"
//                 >
//                   <EmailIcon size={32} round bgStyle={{ fill: "#6464ff" }} style={{ margin: '0 7px' }} />
//                 </EmailShareButton>
//                 <LinkedinShareButton
//                   url={refferalData?.refferalData && refferalData?.refferalData?.link || ""}
//                   quote="link"
//                   className="Demo__some-network__share-button me-2"
//                 >
//                   <LinkedinIcon size={32} round style={{ margin: '0 7px' }} />
//                 </LinkedinShareButton>
//                 <TwitterShareButton
//                   url={refferalData?.refferalData && refferalData?.refferalData?.link || ""}
//                   quote="link"
//                   className="Demo__some-network__share-button me-2"
//                 >
//                   <TwitterIcon size={32} round style={{ margin: '0 7px' }} />
//                 </TwitterShareButton>
//               </div>
//             </div>
//           </div>


//         </div>

//         <div className="container refer-earn-container bottom_container_wr px-3">
//           <div
//             className="reffer_frnd_bottom_wr d-flex "
//             style={{
//               backgroundImage: `url(${ImagePath?.refferErarnBackground})`,
//             }}
//           >
//             <div className="bottom_rff_ern_leftSide">
//               <h5>Refer Friend & Receive Cash</h5>
//               <div className="points_wr">
//                 <div className="points_row_wr">
//                   <span>
//                     1. Refer a friend and get Rs 300 per referral.
//                   </span>
//                 </div>
//                 <div className="points_row_wr">
//                   <span>
//                     2. Get 15% brokerage on every trade by your referral.
//                   </span>
//                 </div>
//                 <div className="points_row_wr">
//                   <span>
//                     3. Your referral gets benefits worth Rs 10,000+ all because
//                     of you.
//                   </span>
//                 </div>
//               </div>
//               <a className="term_condition"
//                 href="https://bigul.co/terms-and-conditions"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 &nbsp;Terms & Conditions.
//               </a>

//             </div>
//             <div className="bottom_rff_ern_rightSide">
//               <img src={refferErarnImg} alt="reffer" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;




import "./Header.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import refferErarnImg from "../../assets/images/referandEarn/dashboard/refferErarnImg.svg"
import { getRefferlLInkCount } from "../../store/ReferralLink/action";
import { ImagePath } from "../../helpers/ImagePaths";
import { getaddRefferlNumber, resetReferralState } from "../../store/AddReferal/action"
import flotingloaderFour from '../../assets/gif/algo/floadtingfour.gif';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { POST_GET_REFERRAL_EARNING } from "../../helpers/url_helper";
import Encrypted from "../Dashboard/Encripted";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [mobileNumber, setMobileNumber] = useState("");
  const [responseData, setResponseData] = useState("");
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const unCodeUser = client_code
  // const unCodeUser = "Y0WX32"
  const [termsChecked, setTermsChecked] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addMobile, setAddMobile] = useState('');
  const [addMobileMSG, setAddMobileMSG] = useState('');
  const client_type = localStorage.getItem("client_type");
const navigate=useNavigate()

//  useEffect(()=>{
//   client_type == 'Bonanza'&&
//     navigate('/')
//   },[client_type])

  const durationPass = "one_year"
  const { AddReferalNumber } = useSelector((state) => ({
    AddReferalNumber: state?.AddReferalNumber,
  }));
  console.log(AddReferalNumber,'AddReferalNumber');
  
  useEffect(() => {
    dispatch(
      getRefferlLInkCount({
        code: unCodeUser,
        referred_by: "CLIENT",
        client_code: client_code,
        client_token: client_token
      })
    );

    getTotalEarningShow({ durationPass })
  }, []);

  const refferalData = useSelector((state) => ({
    refferalData: state?.refferalData?.refferalData,
  }));



  useEffect(() => {
    navigate('/')
    setAddMobile(AddReferalNumber?.AddReferalNumber?.message);
    setAddMobileMSG(AddReferalNumber?.AddReferalNumber?.success)
    AddReferalNumber?.AddReferalNumber?.status == true ? updateReferal() : null
  }, [AddReferalNumber])
  useEffect(() => {
 
  if(AddReferalNumber?.AddReferalNumber.status == true){
    setLoader(false)
   
  }
  if(AddReferalNumber?.error){
    setLoader(false)
    setError(AddReferalNumber?.error)
}
  if(AddReferalNumber?.AddReferalNumber.status == false){
    setLoader(false)
    setError(AddReferalNumber?.AddReferalNumber.message)
  }
  
  }, [AddReferalNumber])

  console.log("check the message off mobile>>", addMobile);
  const updateReferal = () => {
    setResponseData("")
    getTotalEarningShow({ durationPass })
  }

   

  const addMobileNumber = () => {
    setAddMobile('');
    dispatch(resetReferralState());
    if (!mobileNumber) {
      setError("Please enter a 10-digit mobile number to proceed.");
      return;
    }
    if (mobileNumber.length !== 10) {
      setError('Please ensure your mobile number is exactly 10-digits.');
      return;
    }
    if (!termsChecked) {
      setError("Please accept the terms and conditions to proceed.");
      return;
    }
    dispatch(
      getaddRefferlNumber({
        mobile: mobileNumber,
        refcode: unCodeUser,
        refname:client_type,
        mx_Source_URL: "https://dashboard.bigul.co/refer-and-earn",
        client_code: client_code,
        client_token: client_token
      })
    );
    setLoading(true)
    setLoader(true)
    setError("")
  }
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.startsWith('-')) {
      setError("Please enter a valid mobile number (numbers only).");
      setMobileNumber("");
      return;
    }
    if (inputValue.startsWith('+')) {
      setError("Please enter a valid mobile number (numbers only).");
      setMobileNumber("");
      return;
    }
    if (/^1/.test(inputValue)) {
      setError("Please enter a valid mobile number");
      setMobileNumber("");
      return;
    }
    if (/^2/.test(inputValue)) {
      setError("Please enter a valid mobile number");
      setMobileNumber("");
      return;
    }
    if (/^3/.test(inputValue)) {
      setError("Please enter a valid mobile number");
      setMobileNumber("");
      return;
    }
    if (/^4/.test(inputValue)) {
      setError("Please enter a valid mobile number");
      setMobileNumber("");
      return;
    }
    if (/^5/.test(inputValue)) {
      setError("Please enter a valid mobile number");
      setMobileNumber("");
      return;
    }
    if (!/^\d+$/.test(inputValue)) {
      setError("Please enter a valid mobile number (numbers only).");
      setMobileNumber("");
      return;
    }

    setMobileNumber(inputValue);
    setLoading(false);
    setError("");

  };
  const handleCheckboxChange = () => {
    if (mobileNumber == "") {
      setError("Please enter mobile number first")
      return
    }
    setTermsChecked(!termsChecked);
  };

  const getTotalEarningShow = async ({ durationPass }) => {
    try {
      const apiUrl = POST_GET_REFERRAL_EARNING;
      const payload = {
        duration: durationPass,
        refcode: unCodeUser,
        client_code: client_code,
        client_token: client_token
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'enc': Encrypted([payload]) }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data?.status == true) {
        setResponseData(data);
      } else {
        setResponseData(null)
      }

    } catch (error) {
      setResponseData(null)
      console.error('Error fetching data:', error);
    }
  };

  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand('copy');
  };
  console.log("::::::::::::::::::::::", responseData);
  return (
    <div
      className="main_wr pt-2"
    >
      <div className="d-flex justify-content-center"></div>
      <div className="container refer-earn-container">
        <div className="row main_body_wr">
          <div className="col-md-8">
            <div className="my_referral_wr">
              <div className="d-flex align-items-center">
                <div className="my_refferal_icon_wr d-flex">
                  <img src={ImagePath?.myRefferalIcon} alt="my refferal" />
                </div>
                <span className="my_refferal_text">My Referrals</span>
              </div>

              <div className="my_reff_child_wr d-flex">
                <div className="my_reff_first_child ">
                  <span className="acc_process_heading_text">
                    Total Referred
                  </span>
                  <h5 className="acc_process_heading_value">
                    {responseData?.status == true ?
                      parseFloat(responseData?.data?.AllRefer)
                      : responseData == null ? 0 :
                        <img src={flotingloaderFour} alt="" className='card-loader-img' />
                    }
                  </h5>
                </div>
                <div className="my_reff_sec_child ">
                  <div className="acc_in_process ">
                    <span className="acc_process_heading_text">A/c Opened</span>
                    <h5 className="acc_process_heading_value">
                      {responseData?.status == true ?
                        parseInt(responseData?.data?.AllOpened)
                        : responseData == null ? 0 :
                          <img src={flotingloaderFour} alt="" className='card-loader-img' />
                      }
                    </h5>
                  </div>
                </div>
              </div>
              <div className="my_reff_child_wr d-flex">
                <div className="my_reff_first_child ">
                  <span className="acc_process_heading_text">Traded Users</span>
                  <h5 className="acc_process_heading_value">
                    {responseData?.status == true ?
                      parseInt(responseData?.data?.total_traded_count)
                      : responseData == null ? 0 :
                        <img src={flotingloaderFour} alt="" className='card-loader-img' />
                    }
                  </h5>
                </div>
                <div className="my_reff_sec_child ">
                  <div className="acc_in_process ">
                    <span className="acc_process_heading_text">
                      A/c Opening In process
                    </span>
                    <h5 className="acc_process_heading_value">
                      {responseData?.status == true ?
                        parseInt(responseData?.data?.AllInProgress)
                        : responseData == null ? 0 :
                          <img src={flotingloaderFour} alt="" className='card-loader-img' />
                      }
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="my_earnings_wr">
              <div className="d-flex my_earning_header" style={{ alignItems: "center" }}>
                <div className="d-flex align-items-center">
                  <div className="my_refferal_icon_wr d-flex">
                    <img src={ImagePath?.myRefferalIcon} alt="my refferal" />
                  </div>
                  <span className="my_refferal_text">My Earnings</span>
                </div>
                <span className="acc_process_heading_text">Last 12 months income</span>
              </div>

              <div className="earnings_items_wr pt-5 d-flex">
                <div className="earnign_item_item_wr">
                  <div className="image_wr">
                    <img
                      src={ImagePath?.totalEarningIcon}
                      alt="total earning"
                    />
                  </div>
                  <div className="earning_text">
                    <span>Total Earning</span>
                  </div>
                  <h5>

                    ₹{(responseData == "" || responseData ==null) ?
                      0
                      : parseFloat(responseData?.data?.total_earning?.replace(/,/g, ''))?.toLocaleString("en-IN")
                    }
                  </h5>
                </div>

                <div className="line" />

                <div className="earnign_item_item_wr">
                  <div className="image_wr">
                    <img
                      src={ImagePath?.trading_image}
                      alt="total earning"
                    />
                  </div>
                  <div className="earning_text">
                    <span>Activation Income</span>
                  </div>
                  <h5>
                    ₹{(responseData == "" || responseData ==null) ?
                      0
                      : parseFloat(responseData?.data?.total_trade_earning?.replace(/,/g, ''))?.toLocaleString("en-IN")
                    }
                  </h5>
                </div>

                <div className="line" />
                <div className="earnign_item_item_wr">
                  <div className="image_wr">
                    <img
                      src={ImagePath?.amountCreditLedgreIcon}
                      alt="total earning"
                    />
                  </div>
                  <div className="earning_text">
                    <span>Account Opening Income</span>
                  </div>
                  <h5>
                    ₹{(responseData == "" || responseData ==null) ?
                      0
                      : parseFloat(responseData?.data?.totalAcountEarning?.replace(/,/g, ''))?.toLocaleString("en-IN")
                    }
                  </h5>
                </div>

                <div className="line" />
                <div className="earnign_item_item_wr">
                  <div className="image_wr">
                    <img
                      src={ImagePath?.remainingBalanceIcon}
                      alt="total earning"
                    />
                  </div>
                  <div className="earning_text"> 
                    <span>Brokerage income</span>
                  </div>
                  <h5>
                    ₹{(responseData == "" || responseData ==null) ?
                      0
                      :
                      parseFloat(responseData?.data?.total_brok_earning?.replace(/,/g, ''))?.toLocaleString("en-IN")
                    }
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="add_referral_wr h-100">
              <div className="d-flex align-items-center">
                <div className="my_refferal_icon_wr d-flex">
                  <img src={ImagePath?.addRefferalIcon} alt="my refferal" />
                </div>
                <span className="my_refferal_text">Add a Referral</span>
              </div>

              <div className="form_fields_wr mt-3">
                <span>Refer friends, family, networks to earn reward!</span>
                <p className="p-label">Mobile Number</p>
                <input
                  className="inpute_field_wr"
                  type="text"
                  placeholder="Enter a mobile number"
                  value={mobileNumber}
                  style={{ color: "black" }}
                  maxLength="10"
                  onChange={handleInputChange}
                />
              </div>
              {error && <p style={{ color: "red", fontSize: '12px' }}>{error}</p>}

              <div className="term_conditon_wr d-flex flex-column align-items-start pt-3 pb-3">
                <label>
                  <input
                    className="mt-1"
                    type="checkbox"
                    id="termsCheckbox"
                    checked={termsChecked}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;
                  I Agree to the referral scheme
                  <a
                    href="https://bigul.co/terms-and-conditions/client-referral-terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="term_condition_blue" 
                  >
                    &nbsp;Terms & Conditions.
                  </a>
                </label>



                {loading == true ? (
                  <p style={addMobileMSG == false ? { color: "red" } : { color: "green" }}>
                    {addMobile}
                  </p>
                ) : null}

                {/* {loading == true ? <>
                  {
                    addMobileMSG = true ?
                      <p style={{ color: "red" }}>
                        {addMobile}
                      </p>
                      :
                      <p style={{ color: "green" }}>
                        {addMobile}
                      </p>
                  } */}

                {/* </> : null} */}

              </div>


              <div className="invite_button_wr d-flex justify-content-center">
                <div
                  className="invite_button"
                  onClick={() => {  addMobileNumber()  }}
                  disabled={true}
                   style={{ cursor: loader ? 'not-allowed' : 'pointer' }}
                >
                  <span style={{ cursor: loader ? 'not-allowed' : 'pointer'}}>{loader ?'Loading...':'Submit'}</span>
                </div>
              </div>

              <div className="text-center">
                <h5>OR</h5>
              </div>


              <div className="referral-link-box mt-4">
                <span className="d-block">Share this referral link with friends, family, network!</span>
                <div className="referral-link-input">
                  <input
                    ref={inputRef}
                    className="inpute_field_wr"
                    type="inpute"
                    value={refferalData?.refferalData && refferalData?.refferalData?.link}
                  />
                  <img src={ImagePath?.copy_image} alt="my refferal" className="copy-icon" style={{ cursor: 'pointer' }} onClick={copyToClipboard} />

                </div>

              </div>

              {/* <Share data={refferalData?.refferalData && refferalData?.refferalData?.link} style={{}} /> */}
              <div className="text-center mobile-none" style={{ marginTop: "10px" }}>
                <FacebookShareButton
                  className="Demo__some-network__share-button"
                  url={refferalData?.refferalData?.link || ""}
                  quote={`Guess what? I've discovered the ultimate trading platform—Bigul! It's mind-blowing, with features like Algo Trading on mobile, Trade from Chart, and Basket orders. Join now using my referral link ${refferalData?.refferalData && refferalData?.refferalData?.link || ""} for incredible rewards. Let's dive in and start trading with Bigul today!`}
                >
                  <FacebookIcon size={32} round style={{ margin: '0 7px' }} />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={`Guess what? I've discovered the ultimate trading platform—Bigul! It's mind-blowing, with features like Algo Trading on mobile, Trade from Chart, and Basket orders. Join now using my referral link ${refferalData?.refferalData && refferalData?.refferalData?.link || ""} for incredible rewards. Let's dive in and start trading with Bigul today!`}
                  quote="link"
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round style={{ margin: '0 7px' }} />
                </WhatsappShareButton>
                <EmailShareButton
                  url={`
        Hi, How are you doing?

        You know, I've started using this amazing smart trading platform, Bigul, and it's truly a game changer. It has some really simple and customized features to trade for everything, from Algo Trading to Options Trading to Mutual Funds.

        And, the best part is - if you join via my referral link ${refferalData?.refferalData && refferalData?.refferalData?.link || ""}, we both get fantastic rewards!

        I think it is a must-try.

        Let's embark on this trading journey together on Bigul.
    `}
                  quote="link"
                  className="Demo__some-network__share-button"
                >
                  <EmailIcon size={32} round bgStyle={{ fill: "#6464ff" }} style={{ margin: '0 7px' }} />
                </EmailShareButton>

                <LinkedinShareButton
                  url={refferalData?.refferalData && refferalData?.refferalData?.link || ""}
                  quote="link"
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon size={32} round style={{ margin: '0 7px' }} />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={refferalData?.refferalData && refferalData?.refferalData?.link || ""}
                  quote="link"
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round style={{ margin: '0 7px' }} />
                </TwitterShareButton>
              </div>
            </div>
          </div>


        </div>

        <div className="container refer-earn-container bottom_container_wr px-3">
          <div
            className="reffer_frnd_bottom_wr d-flex "
            style={{
              backgroundImage: `url(${ImagePath?.refferErarnBackground})`,
            }}
          >
            <div className="bottom_rff_ern_leftSide">
              <h5>Refer Friend & Receive Cash</h5>
              <div className="points_wr">
                <div className="points_row_wr">
                  <span>
                    1. Get ₹250 for each referral's activation within 90 days.
                  </span>
                </div>
                <div className="points_row_wr">
                  <span>
                    2. Refer up to 5 friends in a month and up to 60 friends in a year.
                  </span>
                </div>
                <div className="points_row_wr">
                  <span>
                    3. This offer is valid only when the referral link is shared via the 'Refer & Earn' section of the mobile app or client dashboard.
                  </span>
                </div>
              </div>
              <a className="term_condition"
                href="https://bigul.co/terms-and-conditions/client-referral-terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;Terms & Conditions.
              </a>

            </div>
            <div className="bottom_rff_ern_rightSide">
              <img src={refferErarnImg} alt="reffer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
