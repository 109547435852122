// import React from 'react';
// import CryptoJS from "crypto-js";
// import { PAYMENT_key } from '../../helpers/url_helper';


// // Functional component
// const Encripted = (Dynamic_arr) => {

//     const client_code = localStorage.getItem("client_id");
//     const client_token = localStorage.getItem("client_token");
//     var CryptoJSAesJson = {
//         stringify: function (cipherParams) {
//             var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
//             if (cipherParams.iv) j.iv = cipherParams.iv.toString();
//             if (cipherParams.salt) j.s = cipherParams.salt.toString();
//             return JSON.stringify(j);
//         },
//         parse: function (jsonStr) {
//             var j = JSON.parse(jsonStr);
//             var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
//             if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
//             if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
//             return cipherParams;
//         }
//     }
//     var key = PAYMENT_key;


//     if (Dynamic_arr.length == 0) {
//         var enc_data = 'client_code=' + client_code + '&client_token=' + client_token;
//     } else {
//         var enc_data = '';
// Dynamic_arr.forEach((item, index) => {
//             for (const [key, value] of Object.entries(item)) {
//                 enc_data += `${(key)}=${(value)}&`;
//             }
//         });
//     }


//     var encrypted = CryptoJS.AES.encrypt(JSON.stringify(enc_data), key, { format: CryptoJSAesJson }).toString();

//     var client_code_new = btoa(encrypted);

//     return client_code_new

// }

// export default Encripted;


import React from 'react';
import CryptoJS from "crypto-js";
import { PAYMENT_key } from '../../helpers/url_helper';

// Functional component
const Encrypted = (Dynamic_arr) => {

    const client_code = localStorage.getItem("client_id");
    const client_token = localStorage.getItem("client_token");
    const client_type = localStorage.getItem("client_type");


    var CryptoJSAesJson = {
        stringify: function (cipherParams) {
            var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
            return cipherParams;
        }
    };

    var key = PAYMENT_key;
    var enc_data = 'client_code=' + client_code.trim();

    if (Dynamic_arr.length > 0) {
        Dynamic_arr.forEach((item, index) => {
            for (const [key, value] of Object.entries(item)) {
                if (key !== 'client_code') { // Skip client_code if already present
                    enc_data += `&${key}=${value}`;
                }
            }
        });
    }

    enc_data += `&client_token=${client_token}` + `&client_type=${client_type}`




    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(enc_data), key, { format: CryptoJSAesJson }).toString();
    var client_code_new = btoa(encrypted);

    return client_code_new;
}

export default Encrypted;
