import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

//Dashboard components
import AddonsSlider from "../Dashboard/AddonsSlider";
import EcommerceCart from './EcommerceCart';
// IMAGE
import bigul_bg from "../../assets/images/logo/bigul_bg.png";
import { useNavigate } from "react-router-dom";

const MyCart = () => {
  document.title = "Bigul Admin & Dashboard";
  const client_type = localStorage.getItem('client_type');
    const navigate=useNavigate()
     useEffect(()=>{
        if(client_type == 'Bonanza'){
          navigate('/')
    
        }
        
      },[client_type])

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="My Cart"
            titleMain="My Cart"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />

          <Row style={{borderBottom: '1px solid #80808036'}}>
            <EcommerceCart />
          </Row>

          <Row className="spacing-bottom-m ">
            <AddonsSlider />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyCart;
