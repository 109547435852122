import React from 'react';
import CryptoJS from "crypto-js";
import { PAYMENT_key } from '../../helpers/url_helper';


const EncGetbalance = () => {
    const client_code = localStorage.getItem("client_id");

  var CryptoJSAesJson = {
        stringify: function (cipherParams) {
            var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
            if (cipherParams.iv) j.iv = cipherParams.iv.toString();
            if (cipherParams.salt) j.s = cipherParams.salt.toString();
            return JSON.stringify(j);
        },
        parse: function (jsonStr) {
            var j = JSON.parse(jsonStr);
            var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) });
            if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
            if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
            return cipherParams;
        }
    };

    var key = PAYMENT_key;
    var enc_data = `client_code=${client_code}` ;
    
   
     var encrypted = CryptoJS.AES.encrypt(JSON.stringify(enc_data), key, { format: CryptoJSAesJson }).toString();
    var encryptedData = btoa(encrypted);
   
    

    return encryptedData;
}

export default EncGetbalance;
