import EncClient from "../pages/Dashboard/EncClient";
import EncGetbalance from "../pages/Dashboard/EncGetbalanc";
import EncPan from "../pages/Dashboard/EncPan";
import Encrypted from "../pages/Dashboard/Encripted";
import Encripted from "../pages/Dashboard/Encripted";
import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

export const checkModificationStatusNew = (data) => api.create2(url.MOD_STATUS_NEW, { 'enc': Encripted([data]) });
export const getNotificationsListApi = (data) => api.get(url.GET_NOTIFICATIONS_LIST + 'enc=' + data.enc);


export const getELearningApiListApi = (data) => api.get(url.GET_ELEARNINGVIDEOS_LIST + 'enc=' + Encripted([]));

export const getELearningACategoryListApi = (data) => api.get(url.GET_ELEARNINGVIDEOS_CATEGORY_LIST + 'enc=' + Encripted([]));

export const getELearningByCategoryApi = (data) => api.get(url.GET_ELEARNINGVIDEOS_BY_CATEGORY_LIST + `category=${data.e_id}&client_code=${data.client_code}&client_token=${data.client_token}`);

export const getProfileDetailsApi = (data) => api.get(url.GET_PROFILE_DETAILS + '?data=' + Encrypted([]));
export const getCollateral_new = (data) => api.get((data == 'Bigul'?url.COLATRAL_NEW_API:url.COLATRAL_NEW_API_BONANZA) + '?data=' + Encrypted([]));
export const getPayout_new = (data) => api.get(url.PAYOUT_NEW_API + '?data=' + Encrypted([]));
export const getCashbalance_new = (data) => api.get(url.BALANCE_NEW_API + '?enc_data=' + EncClient());
export const getCashbalance_new1 = (data) => api.get(url.BALANCE_NEW_API_BONANZA + '?data=' +  EncGetbalance());
export const getHighlightsDetailsApi = (data) => api.get(url.GET_HIGHLIGHTS_DETAILS + 'enc=' + Encripted([]));
export const getSuggestedAlgoApi = (data) => api.get(url.GET_SUGGESTED_ALGO + 'enc=' + Encripted([]));
export const getAddOnApi = (data) => api.get(url.GET_Add_On + 'enc=' + Encripted([]));
export const getFeatureAndPlanApi = (data) => api.get(url.GET_FEATURE_AND_PLAN + 'enc=' + Encripted([]));
export const getMyPlanApi = (data) => api.get(url.GET_MY_PLAN + 'enc=' + Encripted([]));
export const getMyPlanDurationApi = (data) => api.get(url.GET_MY_PLAN_DURATION + 'enc=' + Encripted([]));
export const getMyFeaturePlanApi = (data) => api.get(url.GET_MY_FEATURE_PLAN + 'enc=' + Encripted([]));
export const getMyFeatureSuggestedPlanApi = (data) => api.get(url.GET_MY_FEATURE_SUGGESTED_PLAN + 'enc=' + Encripted([]));

export const getGenerateKeyApi = () => api.get(url.GET_GENERATE_KEY);

export const getEncryptKeyApi = (data) => api.get(url.GET_ENCRYPT_KEY + `strUserId=${data.client_id}`);

export const getTradeBookCashApi = (data) => api.get(`${url.GET_TRADE_BOOK_CASH}apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}&sdate=${data.startDate}&edate=${data.endDate}&dbyr=24-25`);

export const getTradeBookDerivativeApi = (data) => api.get(`${url.GET_TRADE_BOOK_DERIVATIVE}apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}&sdate=${data.startDate}&edate=${data.endDate}&dbyr=24-25`);

export const getLedgerApi = (data) => api.get(`${url.GET_LEDGER}apikey=${encodeURIComponent(data.apiKey)}&dbyr=${data.fYear}&firm=SEC&code=${EncClient()}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=N&filter=ALL&Seg=ALL&ascdsc=ASC`);

export const getPAndLCashApi = (data) => api.get(`${url.GET_P_AND_L_CASH}apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${EncClient()}&sdate=${data.startDate}&edate=${data.endDate}&seg=C&inclchg=${data.charges}`);

//export const getPAndLDerivativeApi = (data) => api.get(`${url.GET_P_AND_L_DERIVATIVE}apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&seg=${data.segment}&inclchg=${data.charges}`);
export const getPAndLDerivativeApi = (data) => api.get(`https://clientlevel.bonanzaonline.com:3031/Bonanza_API/CLSqlInterface.svc/glder?apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${EncClient()}&sdate=${data.startDate}&edate=${data.endDate}&seg=${data.segment}&inclchg=${data.charges}`);

export const getPAndLDerivative2Api = (data) => api.get(`${url.P_AND_L_DERIVATIVE_2}apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&dcode=${EncClient()}&sdate=${data.startDate}&edate=${data.endDate}&seg=${data.segment}&inclchg=${data.charges}`);

export const getHoldingsApi = (data) => api.get(`${url.GET_HOLDING}apikey=${encodeURIComponent(data.apiKey)}&dbyr=24-25&dcode=${EncClient()}&ordby=scripname&ascdsc=ASC&ratetype=M&Seg=C&tot=N&dp=N&Hdate=-`);

export const getTaxReportApi = (data) => api.get(`${url.GET_TAX_REPORT}apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}&sdate=${data.startDate}&edate=${data.endDate}`);

export const getMarginOverviewApi = (data) => api.get(`${url.GET_AVAILABLE_MARGIN}apikey=${encodeURIComponent(data.apiKey)}&firm=SEC&dcode=${EncClient()}`);

export const getBankDetailsApi = (data) => api.get(`${url.GET_BANK_DETAIlS}apikey=${encodeURIComponent(data.apiKey)}&firm=SEC&dcode=${EncClient()}`);

// export const withdrawApi = (data) => api.get(`${url.GET_WITHDRAW}apikey=${encodeURIComponent(data.apiKey)}&firm=SEC&dcode=${EncClient()}&trftype=NEFT&amt=${data.amount}`);
export const withdrawApi = (data) => api.get(`${url.GET_WITHDRAW}` + Encripted([data]));


export const getInvestmentSummaryApi = (data) => api.get(`${url.GET_INVESTED_SUMMARY}apikey=${encodeURIComponent(data.apiKey)}&dbyr=24-25&dcode=${EncClient()}&ratetype=N&seg=C`);

export const getMyInvestedAlgosApi = (data) => api.create(url.GET_MY_INVESTED_ALGO_TOP, { 'enc': Encripted([data]) });

export const getMyInvestedAlgosGraphApi = (data) => api.create(url.GET_MY_INVESTED_ALGO_GRAPH, { 'enc': Encripted([data]) });

export const geCurrentOverviewPMSApi = (data) => api.create(url.GET_CURRENT_OVERVIEW_PMS, { 'enc': EncPan(data?.pan) });



export const geCurrentOverviewMFApi = (data) => api.create(url.GET_CURRENT_OVERVIEW_MF, { 'enc': EncPan(data?.pan) });

export const getCollateralApi = (data) => api.get(url.COLLATERAL_GET + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}`);

export const getCurrentOverviewSAApi = (data) => api.create(url.GET_CURRENT_OVERVIEW_SA, { 'enc': EncPan(data?.pan) });

export const getCartApi = (data) => api.create(url.GET_GET_CART_LIST, { 'enc': Encripted([]) });

export const addItemToCartApi = (data) => api.create(url.ADD_ITEM_TO_CART, { 'enc': Encripted([data]) });

export const removeItemToCartApi = (data) => api.create(url.REMOVE_ITEM_TO_CART, { 'enc': Encripted([data]) });

export const PaymentDetailsApi = (data) => api.create(url.GET_PAYMENT_DETAILS, { 'enc': Encripted([data]) });

export const getSummaryTableApi = (data) => api.create(url.GET_SUMMARY, { 'enc': Encripted([data]) });

export const getPlanHistoryApi = (data) => api.create(url.GET_PLAN_HISTORY + `start_date=${data.startDate}&to_date=${data.endDate}&client_code=${data.client_code}`, { 'enc': Encripted([data]) });

export const getAlgoTranstionList = (data) => api.get(url.GET_ALGO_TRANSATIONS + `${data.ctcl_id}&start_date=${data.start_date}&end_date=${data.end_date}&client_code=${data.client_code}`);
export const getAlgoList = (data) => api.get(url.GET_ALGO_LIST + `${data.client_code}`);

export const AlgoStatusGet = (data) => api.get(url.GET_AlgoStatusCHeck + `enc=${Encripted([])}`);
export const getAlgoDashboard = (data) => api.get(url.Algo_Dashboard + `${data.client_code}`);
export const getAlgoPLListApi = (data) => api.get(url.algo_PL_List + `${data.ctcl_id}&start_date=${data.start_date}&end_date=${data.end_date}&client_code=${data.client_code}`)

export const UpdateItemToCartApi = (data) => {
  ////console.log(data)
  // return api.create(url.UPDATE_ITEM_TO_CART + data.data2 , data.data)};
  return api.create(url.UPDATE_ITEM_TO_CART + { 'enc': Encripted([data.data2, data.data]) })
};

export const submitOtpApi = (data) => api.create(url.SUBMIT_OTP, { 'enc': Encrypted([data]) });

export const resendOtpApi = (data) => api.create(url.RESEND_OTP, { 'enc': Encrypted([data]) });

export const sendApi = (data) => api.create(url.SEND_OTP, { 'enc': Encrypted([data]) });

export const sendApi2 = (data) => api.create(url.SEND_BANK, { 'enc': Encrypted([data]) });

export const callBackApi = (data) => api.create(url.CALL_BACK, { 'enc': Encrypted([data]) });

export const downloadMarginOverviewApi = (data) => api.get(url.DOWNLOAD_MARGIN + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=24-25&firm=SEC&code=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=Y&filter=ALL&Seg=ALL&ascdsc=ASC`);

export const latestUpdateOnApi = (data) => api.create(url.LATEST_UPDATE_ON, data);

export const notificationcountremove = (data) => api.create(url.NOTIFICATION_COUNT_REMOVE, data);

export const SymphonyWithdrawalBalance = (data) => api.create(url.SYMPHONY_WITHDRAWAL_API, { 'data': Encrypted([data]) });

export const ledgerApi = (data) => api.get(url.LEDGER_API + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&firm=SEC&code=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=N&filter=ALL&Seg=ALL&ascdsc=ASC`);

export const collateralApi = (data) => api.get(url.COLLATERAL_API + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}`);

export const marginApi = (data) => api.get(url.MARGIN_API + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=23-24&firm=SEC&code=${data.code}&sdate=${data.startDate}&edate=${data.endDate}&exch=ALL&mar=Y&filter=ALL&Seg=ALL&ascdsc=ASC`);

export const selectedPlanApi = (data) => api.get(url.ELECTED_PLAN + `${data.planID}?client_code=${data.code}&client_token=${data.token}`);

export const selectedFeatureApi = (data) => api.get(url.SELECTED_FEATURE + `${data.planID}?client_code=${data.code}&client_token=${data.token}`);

export const referAndEarnApi = (data) => api.create(url.REFER_AND_EARN, { 'data': Encrypted([]) });

export const pmsDownloadApi = (data) => api.create(url.PMS_DOWNLOAD, data);

// export const taxReportListApi = (data) => api.get(url.TAX_REPORT_LIST_GET + `client_code=${data.code}&client_token=${data.token}`);
export const taxReportListApi = (data) => api.get(url.TAX_REPORT_LIST_GET + 'enc=' + Encrypted([]));

export const taxDetailsApi = (data) => api.get(url.TAX_DETAILS_GET +data?.id+ '?enc=' + Encrypted([data]));

export const modMobileCheck = (data) => api.get(url.mod_mobile_check + `?fn=validateMobile&mobile=${data.mobile}`);

export const ModEmailCheck = (data) => api.create(url.mod_email_check, data);
export const ModEmailCheck_new = (data) => api.create2(url.SEND_OTP_MODI_NEW, { 'enc': Encripted([data]) });
export const Verify_otp_modi_new = (data) => api.create2(url.VERIFY_OTP_MODI_NEW, { 'enc': Encripted([data]) });

export const taxReportApi = (data) => api.get(url.TAX_REPORT_GET + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}&sdate=01-04-${data.start_year}&edate=31-03-${data.end_year}`);

export const contractNoteMonthlyApi = (data) => api.get(url.CONTRACT_NOTE_MONTHLY + `apikey=${encodeURIComponent(data.apiKey)}&dbyr=${data.ddyr_send}&dcode=${EncClient()}&mthinname=${data.year}`);

export const contractNoteDownloadApi = (data) => api.get(url.CONTRACT_NOTE_DOWNLOAD + `apikey=${encodeURIComponent(data.apiKey)}&Filename=${data.fileName}`);

export const getProfileBusinessDetailsApi = (data) => api.create2(url.PROFILE_BUSINESS_INFO, data);

export const getProfileNomineeApi1 = (data) => api.get(url.PROFILE_NOMINEE_INFO1 + '?data=' + Encrypted([data]));

export const getProfileNomineeApi2 = (data) => api.get(url.PROFILE_NOMINEE_INFO2 + '?data=' + Encrypted([data]));

export const pledgeUpdate = (data) => api.get(url.PLEDGE_UPDATE + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}&scode=${data.scode}&isin=${data.isin}&dpcode=${data.dpcode}&dpid=${data.dpid}&pdpcode=${data.pdpcode}&pdpid=${data.pdpid}&tdate=${data.date}&qty=${data.qty}`);

export const mobileNumberUpdateApiCall = (data) => api.create2(url.MOBILE_NO_UPDATE, data);
export const mobileNumberUpdate_new = (data) => api.create2(url.MOBILE_UPDATE_NEW, { 'enc': Encripted([data]) });
export const mobileNumberUpdate_new2 = (data) => api.create3(url.MOBILE_UPDATE_NEW, { 'enc': Encripted([data]) });

export const generateOtp = (data) => api.create2(url.GENERATE_OTP, data);
export const generateOtpNew = (data) => api.create2(url.GENERATE_OTP_NEW, { 'enc': Encripted([data]) });

export const modification_profile = (data) => api.get(url.MODIFICATION_PROFILE + `/${data}`);

export const verifyOtp = (data) => api.create2(url.VERIFY_OTP, data);
export const verifyOtpNew = (data) => api.create2(url.VERIFY_OTP_NEW, { 'enc': Encripted([data]) });

export const modMailSend = (data) => api.create(url.mod_mail_send, data);
export const esignPayment = (data) => api.create(url.ESIGN_PAYMENT, data);

export const modUpdateIscompleteSend = (data) => api.create(url.mod_update_iscomplete_send, data);

export const resendOtp = (data) => api.create2(url.GENERATE_OTP_NEW, { 'enc': Encripted([data]) });

export const getBankMasterInfo = (data) => api.create4(url.BANK_MASTER_INFO, data);

export const getBankMasterInfo2 = (data) => api.get(url.BANK_MASTER_INFO2 + `${data}`);

export const pennyDrop = (data) => api.create(url.PENNY_DROP, data);

export const checkModificationStatus = (data) => api.create2(url.MOD_STATUS_NEW, { 'enc': Encripted([data]) });
export const modificationEsign = (data) => api.create2(url.MOD_ESIGN, { 'enc': Encripted([data]) });
export const sendMobile_new = (data) => api.create2(url.MOD_SEND_MOBILE_NEW, { 'enc': Encripted([data]) });

export const checkModificationStatusAll = (data) => api.create2(url.MOD_STATUS_ALL, data);
export const checkModificationStatusAllNew = (data) => api.get2()

export const dpShameList = (data) => api.get2(url.DP_SHAME_LIST);

export const emailAddressUpdateApiCall = (data) => api.create2(url.EMAIL_UPDATE, data);

export const DDPIStatusUpdateApiCall = (data) => api.create2(url.DDPI_UPDATE, data);

export const DDPIESign = (data) => api.create2(url.DDPI_ESIGN, data);

export const AfterESignDeduction = (data) => api.create3(url.AFTER_E_SIGN, data);

export const DDPIShameESign = (data) => api.create2(url.ALL_ESIGN, data);

export const DPSchemeUpdateApiCall = (data) => api.create2(url.DP_SCHEME_UPDATE, data);

export const PasswordUpdateApiCall = (data) => api.create2(url.PASSWORD_UPDATE, data);

export const MpinUpdateApiCall = (data) => api.create2(url.MPIN_UPDATE, data);

export const SegmentActivationApiCall = (data) => api.create3(url.SEGMENT_ACTIVATION, data);

export const AddNomineeApiCall = (data) => api.create3(url.ADD_NOMINEE, data);

export const AddBankApiCall = (data) => api.create3(url.ADD_BANK, data);

export const BankMod = (data) => api.create2(url.BANK_MOD, data);

export const Verify = (data) => api.create23(url.VERIFY, data);

export const getClientIDApi = (data) => api.create2(url.GET_CLIENT_ID, data);

export const getPledgeApi = (data) => api.get(url.PLEDGE + `apikey=${encodeURIComponent(data.apiKey)}&dcode=${EncClient()}&tdate=${data.date}&mdate=${data.date}`);

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = data => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = data => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = data => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// refer and dashboard
export const postTotalReferredApi = (data) =>
  api.postLoginTokken(url.POST_TOTAL_REFERRED, data);
export const getTradeReferSummaryApi = (data) =>
  api.get(url.GET_TRADE_REFER_SUMMARY, data);
export const generateApiKeyApi = (data) => api.get(url.GET_API_KEY_ONE, data);
export const codeEncryptApi = (data) => api.get(url.GET_ENCRYPTED_CODE, data);
export const postAllUnconvertedLeadsApi = (data) => api.postMethodWithTokenType(url.POST_ACC_OPENING_PROCESS, { 'enc': Encrypted([data]) });
export const postAddReferlNumber = (data) =>
  api.postLoginTokken(
    url.POST_ADD_REFERAL_NUMBER,
    data
  );
export const postReferralLinkApi = (data) =>
  api.postMethodWithTokenType(
    url.POST_REFERRAL_LINK + "generate-referral-link-enc",
    { 'data': Encrypted([]) }
  );


// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = data => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

// Calendar
// get Events
export const getEvents = () => api.get(url.GET_EVENTS);

// get Events
export const getCategories = () => api.get(url.GET_CATEGORIES);

// get Upcomming Events
export const getUpCommingEvent = () => api.get(url.GET_UPCOMMINGEVENT);

// add Events
export const addNewEvent = event => api.create(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = event => api.put(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = event => api.delete(url.DELETE_EVENT, { headers: { event } });

// Chat
// get Contact
export const getDirectContact = () => api.get(url.GET_DIRECT_CONTACT);

// get Messages
export const getMessages = roomId => api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// add Message
export const addMessage = message => api.create(url.ADD_MESSAGE, message);

// add Message
export const deleteMessage = message => api.delete(url.DELETE_MESSAGE, { headers: { message } });

// get Channels
export const getChannels = () => api.get(url.GET_CHANNELS);

// MailBox
//get Mail
export const getMailDetails = () => api.get(url.GET_MAIL_DETAILS);

// delete Mail
export const deleteMail = forId => api.delete(url.DELETE_MAIL, { headers: { forId } });

// Ecommerce
// get Products
export const getProducts = () => api.get(url.GET_PRODUCTS);

// delete Product
export const deleteProducts = product => api.delete(url.DELETE_PRODUCT + '/' + product);

// add Products
export const addNewProduct = product => api.create(url.ADD_NEW_PRODUCT, product);

// update Products
export const updateProduct = product => api.update(url.UPDATE_PRODUCT + '/' + product._id, product);

// get Orders
export const getOrders = () => api.get(url.GET_ORDERS);

// add Order
export const addNewOrder = order => api.create(url.ADD_NEW_ORDER, order);

// update Order
export const updateOrder = order => api.update(url.UPDATE_ORDER + '/' + order._id, order);

// delete Order
export const deleteOrder = order => api.delete(url.DELETE_ORDER + '/' + order);

// get Customers
export const getCustomers = () => api.get(url.GET_CUSTOMERS);

// add Customers
export const addNewCustomer = customer => api.create(url.ADD_NEW_CUSTOMER, customer);

// update Customers
export const updateCustomer = customer => api.update(url.UPDATE_CUSTOMER + '/' + customer._id, customer);

// delete Customers
export const deleteCustomer = customer => api.delete(url.DELETE_CUSTOMER + '/' + customer);

// get Sellers
export const getSellers = () => api.get(url.GET_SELLERS);

// Project
// get Project list 
export const getProjectList = () => api.get(url.GET_PROJECT_LIST);

// Tasks
// get Task
export const getTaskList = () => api.get(url.GET_TASK_LIST);

// add Task
export const addNewTask = task => api.create(url.ADD_NEW_TASK, task);

// update Task
export const updateTask = task => api.update(url.UPDATE_TASK + '/' + task._id, task);

// delete Task
export const deleteTask = task => api.delete(url.DELETE_TASK + '/' + task);

// CRM
// get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS);

// add Contact
export const addNewContact = contact => api.create(url.ADD_NEW_CONTACT, contact);

// update Contact
export const updateContact = contact => api.update(url.UPDATE_CONTACT + '/' + contact._id, contact);

// delete Contact
export const deleteContact = contact => api.delete(url.DELETE_CONTACT + '/' + contact);

// get Companies
export const getCompanies = () => api.get(url.GET_COMPANIES);

// add Companies
export const addNewCompanies = company => api.create(url.ADD_NEW_COMPANIES, company);

// update Companies
export const updateCompanies = company => api.update(url.UPDATE_COMPANIES + '/' + company._id, company);

// delete Companies
export const deleteCompanies = company => api.delete(url.DELETE_COMPANIES + '/' + company);

// get Deals
export const getDeals = () => api.get(url.GET_DEALS);

// get Leads
export const getLeads = () => api.get(url.GET_LEADS);

// add Lead
export const addNewLead = lead => api.create(url.ADD_NEW_LEAD, lead);

// update Lead
export const updateLead = lead => api.update(url.UPDATE_LEAD + '/' + lead._id, lead);

// delete Lead
export const deleteLead = lead => api.delete(url.DELETE_LEAD + '/' + lead);

// Crypto
// Transation
export const getTransationList = () => api.get(url.GET_TRANSACTION_LIST);

// Order List
export const getOrderList = () => api.get(url.GET_ORDRER_LIST);

// Invoice
//get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES);

// add Invoice
export const addNewInvoice = invoice => api.create(url.ADD_NEW_INVOICE, invoice);

// update Invoice
export const updateInvoice = invoice => api.update(url.UPDATE_INVOICE + '/' + invoice._id, invoice);

// delete Invoice
export const deleteInvoice = invoice => api.delete(url.DELETE_INVOICE + '/' + invoice);

// Support Tickets 
// Tickets
export const getTicketsList = () => api.get(url.GET_TICKETS_LIST);

// add Tickets 
export const addNewTicket = ticket => api.create(url.ADD_NEW_TICKET, ticket);

// update Tickets 
export const updateTicket = ticket => api.update(url.UPDATE_TICKET + '/' + ticket._id, ticket);

// delete Tickets 
export const deleteTicket = ticket => api.delete(url.DELETE_TICKET + '/' + ticket);

// Dashboard Analytics

// Sessions by Countries
export const getAllData = () => api.get(url.GET_ALL_DATA);
export const getHalfYearlyData = () => api.get(url.GET_HALFYEARLY_DATA);
export const getMonthlyData = () => api.get(url.GET_MONTHLY_DATA);

// Audiences Metrics
export const getAllAudiencesMetricsData = () => api.get(url.GET_ALLAUDIENCESMETRICS_DATA);
export const getMonthlyAudiencesMetricsData = () => api.get(url.GET_MONTHLYAUDIENCESMETRICS_DATA);
export const getHalfYearlyAudiencesMetricsData = () => api.get(url.GET_HALFYEARLYAUDIENCESMETRICS_DATA);
export const getYearlyAudiencesMetricsData = () => api.get(url.GET_YEARLYAUDIENCESMETRICS_DATA);

// Users by Device
export const getTodayDeviceData = () => api.get(url.GET_TODAYDEVICE_DATA);
export const getLastWeekDeviceData = () => api.get(url.GET_LASTWEEKDEVICE_DATA);
export const getLastMonthDeviceData = () => api.get(url.GET_LASTMONTHDEVICE_DATA);
export const getCurrentYearDeviceData = () => api.get(url.GET_CURRENTYEARDEVICE_DATA);

// Audiences Sessions by Country
export const getTodaySessionData = () => api.get(url.GET_TODAYSESSION_DATA);
export const getLastWeekSessionData = () => api.get(url.GET_LASTWEEKSESSION_DATA);
export const getLastMonthSessionData = () => api.get(url.GET_LASTMONTHSESSION_DATA);
export const getCurrentYearSessionData = () => api.get(url.GET_CURRENTYEARSESSION_DATA);

// Dashboard CRM

// Balance Overview
export const getTodayBalanceData = () => api.get(url.GET_TODAYBALANCE_DATA);
export const getLastWeekBalanceData = () => api.get(url.GET_LASTWEEKBALANCE_DATA);
export const getLastMonthBalanceData = () => api.get(url.GET_LASTMONTHBALANCE_DATA);
export const getCurrentYearBalanceData = () => api.get(url.GET_CURRENTYEARBALANCE_DATA);

// Dial Type
export const getTodayDealData = () => api.get(url.GET_TODAYDEAL_DATA);
export const getWeeklyDealData = () => api.get(url.GET_WEEKLYDEAL_DATA);
export const getMonthlyDealData = () => api.get(url.GET_MONTHLYDEAL_DATA);
export const getYearlyDealData = () => api.get(url.GET_YEARLYDEAL_DATA);

// Sales Forecast
export const getOctSalesData = () => api.get(url.GET_OCTSALES_DATA);
export const getNovSalesData = () => api.get(url.GET_NOVSALES_DATA);
export const getDecSalesData = () => api.get(url.GET_DECSALES_DATA);
export const getJanSalesData = () => api.get(url.GET_JANSALES_DATA);

// Dashboard Ecommerce
// Revenue
export const getAllRevenueData = () => api.get(url.GET_ALLREVENUE_DATA);
export const getMonthRevenueData = () => api.get(url.GET_MONTHREVENUE_DATA);
export const getHalfYearRevenueData = () => api.get(url.GET_HALFYEARREVENUE_DATA);
export const getYearRevenueData = () => api.get(url.GET_YEARREVENUE_DATA);


// Dashboard Crypto
// Portfolio
export const getBtcPortfolioData = () => api.get(url.GET_BTCPORTFOLIO_DATA);
export const getUsdPortfolioData = () => api.get(url.GET_USDPORTFOLIO_DATA);
export const getEuroPortfolioData = () => api.get(url.GET_EUROPORTFOLIO_DATA);

// Market Graph
export const getAllMarketData = () => api.get(url.GET_ALLMARKETDATA_DATA);
export const getYearMarketData = () => api.get(url.GET_YEARMARKET_DATA);
export const getMonthMarketData = () => api.get(url.GET_MONTHMARKET_DATA);
export const getWeekMarketData = () => api.get(url.GET_WEEKMARKET_DATA);
export const getHourMarketData = () => api.get(url.GET_HOURMARKET_DATA);

// Dashboard Project
// Project Overview
export const getAllProjectData = () => api.get(url.GET_ALLPROJECT_DATA);
export const getMonthProjectData = () => api.get(url.GET_MONTHPROJECT_DATA);
export const gethalfYearProjectData = () => api.get(url.GET_HALFYEARPROJECT_DATA);
export const getYearProjectData = () => api.get(url.GET_YEARPROJECT_DATA);

// Project Status
export const getAllProjectStatusData = () => api.get(url.GET_ALLPROJECTSTATUS_DATA);
export const getWeekProjectStatusData = () => api.get(url.GET_WEEKPROJECTSTATUS_DATA);
export const getMonthProjectStatusData = () => api.get(url.GET_MONTHPROJECTSTATUS_DATA);
export const getQuarterProjectStatusData = () => api.get(url.GET_QUARTERPROJECTSTATUS_DATA);

// Dashboard NFT
// Marketplace
export const getAllMarketplaceData = () => api.get(url.GET_ALLMARKETPLACE_DATA);
export const getMonthMarketplaceData = () => api.get(url.GET_MONTHMARKETPLACE_DATA);
export const gethalfYearMarketplaceData = () => api.get(url.GET_HALFYEARMARKETPLACE_DATA);
export const getYearMarketplaceData = () => api.get(url.GET_YEARMARKETPLACE_DATA);

// Project
export const addProjectList = (project) => api.create(url.ADD_NEW_PROJECT, project);
export const updateProjectList = (project) => api.put(url.UPDATE_PROJECT, project);
export const deleteProjectList = (project) => api.delete(url.DELETE_PROJECT, { headers: { project } });

// Pages > Team
export const getTeamData = (team) => api.get(url.GET_TEAMDATA, team);
export const deleteTeamData = (team) => api.delete(url.DELETE_TEAMDATA, { headers: { team } });
export const addTeamData = (team) => api.create(url.ADD_NEW_TEAMDATA, team);
export const updateTeamData = (team) => api.put(url.UPDATE_TEAMDATA, team);

// File Manager

// Folder
export const getFolders = (folder) => api.get(url.GET_FOLDERS, folder);
export const deleteFolder = (folder) => api.delete(url.DELETE_FOLDER, { headers: { folder } });
export const addNewFolder = (folder) => api.create(url.ADD_NEW_FOLDER, folder);
export const updateFolder = (folder) => api.put(url.UPDATE_FOLDER, folder);

// File
export const getFiles = (file) => api.get(url.GET_FILES, file);
export const deleteFile = (file) => api.delete(url.DELETE_FILE, { headers: { file } });
export const addNewFile = (file) => api.create(url.ADD_NEW_FILE, file);
export const updateFile = (file) => api.put(url.UPDATE_FILE, file);

// To Do
export const getTodos = (todo) => api.get(url.GET_TODOS, todo);
export const deleteTodo = (todo) => api.delete(url.DELETE_TODO, { headers: { todo } });
export const addNewTodo = (todo) => api.create(url.ADD_NEW_TODO, todo);
export const updateTodo = (todo) => api.put(url.UPDATE_TODO, todo);

// To do Project
export const getProjects = (project) => api.get(url.GET_PROJECTS, project);
export const addNewProject = (project) => api.create(url.ADD_NEW_TODO_PROJECT, project);

//Job Application
export const getJobApplicationList = () => api.get(url.GET_APPLICATION_LIST);

//API Key
export const getAPIKey = () => api.get(url.GET_API_KEY);
