import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NomineeDetailsEdit from "./NomineeDetailsEdit";
//formik
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
//import NomineeDetailsModelIndex from "../../Components/CustomComponents/Models/nomineeDetails/NomineeDetailsModelIndex";
import {
  AddNomineeApiCall,
  DDPIShameESign,
  checkModificationStatus,
  AfterESignDeduction,
  SymphonyWithdrawalBalance,
  modificationEsign,
  esignPayment,
} from "../../../helpers/fakebackend_helper";

import NomineeDetailsAdd from "./NomineeDetailsAdd";
import axios from "axios";
import { MOD_EKYC_NEW, PAYMENT_ESIGN_URL, Payment_url, Payment_url_Bonanza, redirectApp } from "../../../helpers/url_helper";
import ShowNomineeDetails from "./ShowNomineeDetails";
import NomineeDetailsAdd1 from "./NomineeDetailsAdd12082023";
import Encrypted from "../../Dashboard/Encripted";
import Loader from "../../../Components/Common/Loader";
var amount = 0
const NomineeDetails = (props) => {

  const [modNominee, setModNominee] = useState(0);
  const { data, data2, Balance } = props;


  const [EsignModal, setEsignModal] = useState(false);
  const [balance, setBalance] = useState(false);
  const symphony_token = localStorage.getItem("symphony_token");
  const client_token = localStorage.getItem("client_token");
  const [shouldContinueApi, setShouldContinueApi] = useState(false);
  const [deseble, setDeseble] = useState(false);
  const [nomineeData, setNomineeData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [esignStataus, setEsignStatus] = useState(0)
  const [loading, setLoading] = useState(false)


  const filteredData = nomineeData?.filter(item => item?.entry_type === "nominee_1");
  const filteredData2 = nomineeData?.filter(item => item?.entry_type === "nominee_2");
  const filteredData3 = nomineeData?.filter(item => item?.entry_type === "nominee_3");

  useEffect(() => {

    let intervalId;

    const callApi = () => {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "nominee",

      })
        .then((response) => {
          if (
            response.status === true && response?.data?.modification?.length > 0
          ) {

            setModNominee(1);
            setNomineeData(response?.data?.modification)
            if (response?.data.modification_for[0]?.is_esign == 0) {
              setEsignStatus(1)
              return
            }
            if (response?.data.modification_for[0]?.is_esign == 1) {
              setEsignStatus(2)
              setShouldContinueApi(false)
              return
            }
            if (response?.data.modification_for[0]?.is_send_to_ld == 1) {
              setEsignStatus(3)
              return
            }
            return

          }
          else if (response.status === true && response?.data?.rejection?.length > 0) {
            setModNominee(2);
            return
          }
          else if (response.status === false) {
            setModNominee(2);
            setNomineeData([])
            return
          }
        })
        .catch((err) => {
          toast.error(err?.message || 'something went wrong please try again')
        });

    };

    if (props.activeTab === "4" && shouldContinueApi) {
      intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
    }

    return () => {
      clearInterval(intervalId); // Cleanup on unmount or condition change
    };
  }, [props.activeTab === "4", shouldContinueApi]);



  const handleEsignConfirm = () => {
    setBalance(false)
    if (isNaN(Balance)) {
      toast.error('Unable to fetch your ledger balance. Please try after some time.');
      return;
  }
    if (Balance < 25) {
      setBalance(true)
    }
    else {
      setDeseble(true)
      modificationEsign({
        client_code: client_code,
        entry_type: 'nominee',
      })
        .then((response) => {
          if (response?.status) {
            setDeseble(false)
            window.open(`${response?.redirectUrl}`, "_blank");
            setShouldContinueApi(true)
            setTimeout(() => {
              setEsignModal(false)
            }, 1000);
          }
          else {
            toast(response.message)
            setDeseble(false)
          }
        })
        .catch((err) => {
          setDeseble(false)

          toast('something went wrong')
        });
    }
  }


  const client_code = localStorage.getItem("client_id");
  const client_type = localStorage.getItem("client_type");
  useEffect(() => {
    setShouldContinueApi(false)
  }, [props.activeTab === "4"])

  useEffect(() => {
    // setShouldContinueApi(false)
    setOldData([])
    if (props.activeTab === "4") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "nominee",

      })
        .then((response) => {
          if (
            response.status === true && response?.data?.modification?.length > 0
          ) {
            setModNominee(1);
            setNomineeData(response?.data?.modification)
            if (response?.data.modification_for[0]?.is_esign == 0) {
              setEsignStatus(1)
              return
            }
            if (response?.data.modification_for[0]?.is_esign == 1) {
              setEsignStatus(2)
              return
            }
            if (response?.data.modification_for[0]?.is_send_to_ld == 1) {
              setEsignStatus(3)
              return
            }
            return

          }
          else if (response.status === true && response?.data?.rejection?.length > 0) {
            setModNominee(2);


            return
          }
          else if (response.status === false) {
            setModNominee(2);
            setNomineeData([])
            if (data[0]?.CNOMINEENAME != " ") {
              setOldData(data)
            } else {
              setOldData([])
            }

          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    }
  }, [modNominee, EsignModal]);

  useEffect(() => {
    setLoading(true)
    setOldData([])
    if (props.activeTab === "4") {
      checkModificationStatus({
        client_code: client_code,
        entry_type: "nominee",

      })
        .then((response) => {
          setLoading(false)
          if (
            response.status === true && response?.data?.modification?.length > 0
          ) {
            setModNominee(1);
            setNomineeData(response?.data?.modification)
            if (response?.data.modification_for[0]?.is_esign == 0) {
              setEsignStatus(1)
              return
            }
            if (response?.data.modification_for[0]?.is_esign == 1) {
              setEsignStatus(2)
              return
            }
            if (response?.data.modification_for[0]?.is_send_to_ld == 1) {
              setEsignStatus(3)
              return
            }
            return

          }
          else if (response.status === true && response?.data?.rejection?.length > 0) {
            setModNominee(2);


            return
          }
          else if (response.status === false) {
            setModNominee(2);
            setNomineeData([])
            if (data[0]?.CNOMINEENAME != " ") {
              setOldData(data)
            } else {
              setOldData([])
            }
          }
        })
        .catch((err) => {
          setLoading(false)

        });
    }
  }, [props.activeTab]);



  var checkStatusBlank = "NO";
  if (data.length > 0) {
    var nameget = data[0].CNOMINEENAME;
    nameget = nameget.trim();
    if (nameget.length == 0) {
      checkStatusBlank = "YES";
    }
  }

  const handleChange = () => {
    setModNominee(1)
    setEsignModal(true)
  }
  const handleEsign = () => {
    setEsignModal(true)
    setBalance(false)
  }
  const toggle = () => setEsignModal(!EsignModal);

  // const handlepay = () => {
  //   esignPayment({
  //     enc_data: amount > 0 ? `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent(25- amount)}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}` : `ClientCode=${encodeURIComponent(client_code)}&amount=${encodeURIComponent((Math.abs(amount) + 25))}&redirectUrl=${encodeURIComponent('https://dashboard.bigul.app/profile')}&SubscriptionPlan=${encodeURIComponent('client_modification')}`


  //   })
  //     .then((response) => {
  //       if (response?.message == "Data Encrypted") {
  //         window.location.href = 'https://payment.bigul.app/?enc=' + response?.enc_data;

  //       }
  //       else {
  //         toast('data not found')
  //       }
  //     }).
  //     catch((error) => {
  //       toast('something went wrong')
  //     })
  // }

  const handlepay = () => {
    // Create the payload object with necessary parameters
    const payload = {
      ClientCode: client_code,
      amount: Balance > 0 ? (25 - Balance) : (Math.abs(Balance) + 25),
      redirectUrl: redirectApp,
    };

   window.location.href =  (client_type == 'Bigul' ? Payment_url : Payment_url_Bonanza) + `?enc=${Encrypted([payload])}`

  };


  const handleCancel = async () => {
    setLoading(true)
    try {
      const payload = {
        client_code: client_code,
        entry_type: 'nominee'
      }; // Your POST data here
      const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', { 'enc': Encrypted([payload]) });
      if (response?.status == true) {
        setEsignModal(false)

        setBalance(false)
        setShouldContinueApi(false)
        setTimeout(() => {
          setLoading(false)
        }, 1000);

      } else {
        setTimeout(() => {
          setLoading(false)
        }, 1000);
        toast.error('something went wrong please try again')
      }
    } catch (error) {
      setLoading(false)
      toast.error('something went wrong please try again')
    }
  }
  const filteredResults = filteredData.filter(item => item.modify_key !== "add3" && item.new_value !== "null");
  const filteredResults2 = filteredData2.filter(item => item.modify_key !== "add3" && item.new_value !== "null");
  const filteredResults3 = filteredData3.filter(item => item.modify_key !== "add3" && item.new_value !== "null");



  const keyMapping = {
    CITPANNUMBER: "proof_type_1",
    CCLIENTBOID: "client_boid",
    CLDCLIENTCODE: "client_code",
    CNOMINEENAME: "name",
    CNOMINEEADDRESS1: "address_line_1_1",
    CNOMINEEADDRESS2: "address_line_2_1",
    CNOMINEEADDRESS3: "address_line_3_1",
    CNOMINEECITY: "city_1",
    CNOMINEESTATE: "state_1",
    CNOMINEEPIN: "area_code_1",
    CNOMINEECOUNTRY: "country_1",
    CNOMINEEEMAIL: "nominee_email_1",
    CNOMINEEPHONE: "nominee_mobile_number_1",
    CNOMINEEPAN: "proof_num_1",
    CNOMINEEUID: "uid",
    NNOMINEENO: "nominee_number",
    DMINORDATEOFBIRTH: "nominee_dob_1",
    NSHAREPERCENT: "percentage_1",
    NRELATIONCODE: "relation_code",
    CRELATIONSHIPDESC: "nominee_relation_1"
  };


  const transformArray = (array, keyMapping) => {
    return array.map(obj => {
      const newObj = {};
      for (const oldKey in obj) {
        if (oldKey in keyMapping) {
          const newKey = keyMapping[oldKey];
          // Special handling for "DMINORDATEOFBIRTH" to extract the date
          newObj[newKey] = oldKey === "DMINORDATEOFBIRTH" ? obj[oldKey].date : obj[oldKey];
        }
      }
      return newObj;
    });
  };

  // Transform the original array
  const transformedArray = transformArray(oldData, keyMapping);


  const trimSpacesFromObjects = (array) => {
    return array.map(obj => {
      const newObj = {};
      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          newObj[key] = obj[key].trim();
        } else {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    });
  };

  const trimmedArray = trimSpacesFromObjects(transformedArray);

  if (loading) {
    return <Loader />
  }

  const relationMapping = {
    "1": "Spouse",
    "2": "Son",
    "3": "Daughter",
    "4": "Father",
    "5": "Mother",
    "6": "Brother",
    "7": "Sister",
    "8": "Grand Son",
    "9": "Grand Daughter",
    "10": "Grand Father",
    "11": "Grand Mother",
    "12": "Not Provided",
    "13": "Others"
  };

  return (
    <>
      <Modal isOpen={EsignModal} toggle={toggle} >
        <ModalHeader toggle={toggle} >{balance ? 'Proceed to Pay' : 'Proceed to Esign'}</ModalHeader>
        <ModalBody>
          {
            balance ?
              <h4 className="text-9 text-color5 mt-3 text-center">
                The amount available in your ledger balance is not not sufficient for this transaction.
              </h4>
              :
              <h4 className="text-9 text-color5 mt-3 text-center">
                Please proceed to E-sign to confirm your request for modification. Rs. 25 (including GST) charge will be applicable for modification. The amount will be deducted from your ledger balance.
              </h4>
          }
        </ModalBody>
        <div className="d-flex justify-content-center">

          {
            balance ?
              <>
                <button
                  type="button"
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handlepay}
                >
                  {" "}
                  Proceed to pay
                </button>
                <button
                  type="button"
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'gray',
                    color: 'white',
                    border: '1px solid gray',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleCancel}
                >
                  Cancel Request
                </button>
              </>
              :
              <>
                <button
                  type="button"
                  style={{ marginLeft: '10px' }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleEsignConfirm}
                  disabled={deseble}

                >
                  {" "}
                  {deseble ? 'please wait...' : 'Proceed to eSign'}

                </button>
                <button
                  type="button"
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'gray',
                    color: 'white',
                    border: '1px solid gray',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  className="btn btn-primary modal-content-Button my-3"
                  onClick={handleCancel}
                >
                  Cancel Request
                </button>
              </>
          }
        </div>
      </Modal>
      <Row>
        <Col className="profile-main-head">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
              <h4 className="text-9 text-color5 m-0">Nominee Details</h4>
            </div>
          </div>
        </Col>
      </Row>
      {
        oldData.length > 0 ?
          <>
            <NomineeDetailsAdd data={data} data2={data2} state={modNominee} onStateChange={handleChange} oldData={trimmedArray} />
          </>
          :
          modNominee == 2 ?
            <NomineeDetailsAdd data={data} data2={data2} state={modNominee} onStateChange={handleChange} />
            :
            <div>

              {filteredData?.length > 0 &&
                <>
                  <div style={{ marginLeft: '40px' }}>
                    <h4 className="text-9 text-color5 m-0 mt-3 mb-3" >Nominee 1</h4>
                  </div>
                  {
                    filteredResults?.map((item) => (
                      
                        // item?.new_value == ''
                        // ?
                        // null:
                      
                      <Row className="mb-3" style={{ marginLeft: '30px' }}>
                        <Col lg={1} style={{ width: '150px' }}>
                          <Label htmlFor="confirmAccountNumberInput" className="py-2 m-0 text-101 text-color5">
                            {item?.modify_label}
                          </Label>
                        </Col>
                        <Col lg={6} style={{ marginLeft: '30px' }}>
                          <Input
                            className="nominee-form-input text-color1 text-4"
                            value={
                              item?.modify_label === "Relation"
                                ? relationMapping[item?.new_value] : item?.new_value
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  <div className="profile-main-head"></div>
                </>
              }
              {
                filteredData2?.length > 0 &&
                <>
                  <div style={{ marginLeft: '40px' }}>
                    <h4 className="text-9 text-color5 m-0 mt-3 mb-3" >Nominee 2</h4>
                  </div>
                  {
                    filteredResults2?.map((item) => (
                      <Row className="mb-3" style={{ marginLeft: '30px' }}>
                        <Col lg={1}>
                          <Label htmlFor="confirmAccountNumberInput" className="py-2 m-0 text-101 text-color5">
                            {item?.modify_label}
                          </Label>
                        </Col>
                        <Col lg={6} style={{ marginLeft: '30px' }}>
                          <Input
                            className="nominee-form-input text-color1 text-4"
                            value={
                              item?.modify_label === "Relation"
                                ? relationMapping[item?.new_value] : item?.new_value
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  <div className="profile-main-head"></div>

                </>
              }
              {
                filteredData3?.length > 0 &&
                <>
                  <div style={{ marginLeft: '40px' }}>
                    <h4 className="text-9 text-color5 m-0 mt-3 mb-3" >Nominee 3</h4>
                  </div>
                  {
                    filteredResults3?.map((item) => (
                      <Row className="mb-3" style={{ marginLeft: '30px' }}>
                        <Col lg={1}>
                          <Label htmlFor="confirmAccountNumberInput" className="py-2 m-0 text-101 text-color5">
                            {item?.modify_label}
                          </Label>
                        </Col>
                        <Col lg={6} style={{ marginLeft: '30px' }}>
                          <Input
                            className="nominee-form-input text-color1 text-4"
                            value={
                              item?.modify_label === "Relation"
                                ? relationMapping[item?.new_value] : item?.new_value
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  <div className="profile-main-head"></div>

                </>
              }

              {
                esignStataus == 1 ?
                  <p style={{ backgroundColor: "#5367FC", padding: '7px', borderRadius: 5, color: 'white', cursor: 'pointer', width: '150px', marginTop: '20px', textAlign: 'center', }} onClick={handleEsign}>Esign Pending</p>
                  :
                  esignStataus == 2 ?
                    <p className="text-5 text-color10 m-0"

                    >Status : Pending verification</p>
                    :
                    <p style={{ marginTop: '20px', color: 'green' }}>Status : In progress</p>

              }
            </div>
      }
    </>
  );
};

export default NomineeDetails;

