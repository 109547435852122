// import { useEffect, useState } from "react";
// import { SymphonyWithdrawalBalance, checkModificationStatus, esignPayment, modificationEsign } from "../../helpers/fakebackend_helper";
// import { Col, Input, Row, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
// import { Alignment } from "react-data-table-component";
// import { toast } from "react-toastify";
// import { logDOM } from "@testing-library/react";
// import axios from "axios";
// import { MOD_EKYC_NEW, redirectApp } from "../../helpers/url_helper";
// import Encrypted from "../Dashboard/Encripted";
// import Loader from "../../Components/Common/Loader";

// var amount = 0
// const AnnualIncome = (props) => {
//     const { data, Balance } = props;
//     const client_code = localStorage.getItem("client_id");
//     const client_token = localStorage.getItem("client_token");
//     const [modiData, setModificationData] = useState()
//     const [check, setCheck] = useState(false)
//     const [status, setStaus] = useState(false)
//     const [selectedValue, setSelectedValue] = useState('');
//     const [selectedId, setSelectedId] = useState('');
//     const [selectProoftype, setSelectProofType] = useState('');
//     const [file, setFile] = useState(null);
//     const [EsignModal, setEsignModal] = useState(false);
//     const [balance, setBalance] = useState(false);
//     const [deseble, setDeseble] = useState(false);
//     const [shouldContinueApi, setShouldContinueApi] = useState(false);
//     const symphony_token = localStorage.getItem("symphony_token");
//     const [password, setPasword] = useState('')
//     const [loading, setLoading] = useState(false)

//     useEffect(() => {
//         setShouldContinueApi(false)
//     }, [props.activeTab === "7"])
//     useEffect(() => {
//         // setShouldContinueApi(false)

//         if (props.activeTab === "7") {
//             setSelectedValue('')
//             setSelectedId('')
//             setSelectProofType("")
//             setCheck(false)
//             setFile(null)
//             setStaus(false)
//             checkModificationStatus({
//                 client_code: client_code,
//                 entry_type: "annual_income",

//             })
//                 .then((response) => {
//                     if (response.status == true && response?.data?.modification?.length > 0) {
//                         setModificationData(response?.data?.modification[0])
//                     }
//                     else {
//                         setModificationData({})
//                     }
//                 })
//                 .catch((err) => {
//                 });
//         }
//     }, [data, status, EsignModal]);
//     useEffect(() => {
//         setLoading(true)

//         if (props.activeTab === "7") {
//             setSelectedValue('')
//             setSelectedId('')
//             setSelectProofType("")
//             setCheck(false)
//             setFile(null)
//             setStaus(false)
//             checkModificationStatus({
//                 client_code: client_code,
//                 entry_type: "annual_income",

//             })
//                 .then((response) => {
//                     setLoading(false)

//                     if (response.status == true && response?.data?.modification?.length > 0) {
//                         setModificationData(response?.data?.modification[0])
//                     }
//                     else {
//                         setModificationData({})
//                     }
//                 })
//                 .catch((err) => {
//                     setLoading(false)

//                 });
//         }
//     }, [props.activeTab === "7"]);

//     const handleEdit = () => {
//         setCheck(true)
//     }
//     const handleSelectChange = (e) => {
//         const value = e.target.value
//         setSelectedValue(value)
//         let id = '';
//         switch (value) {
//             case 'Below 1 Lakh':
//                 id = '1';
//                 break;
//             case '1-5 Lakhs':
//                 id = '2';
//                 break;
//             case '5-10 Lakhs':
//                 id = '3';
//                 break;
//             case '10-25 Lakhs':
//                 id = '4';
//                 break;
//             case '25-100 Lakhs':
//                 id = '5';
//                 break;
//             default:
//                 id = '';
//         }
//         setSelectedId(id);
//     }

//     const handleSelectProof = (e) => {
//         const value = e.target.value
//         setSelectProofType(value)
//     }

//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };

//     const handleSubmit = async () => {
//         const allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
//         const maxFileSize = 2 * 1024 * 1024;
//         if (selectedId > 3 && selectProoftype == '') {
//             toast('please select proof type')
//             return
//         }
//         if (selectedId > 3 && file == null) {
//             toast('please select document')
//             return
//         }
//         if (selectedId > 3 && !allowedFileTypes.includes(file.type)) {
//             toast('Invalid file type. Only PDF, PNG, and JPEG are allowed.');
//             return;
//         }

//         if (selectedId > 3 && file.size > maxFileSize) {
//             toast('File size exceeds 2 MB.');
//             return;
//         }
//         else {
//             setLoading(true)
//             const formData = new FormData();
//             formData.append('client_code', client_code);
//             formData.append('client_token', client_token);
//             formData.append('entry_type', 'annual_income');
//             formData.append('old[annual_income]', null);
//             formData.append('new[annual_income]', selectedId);
//             formData.append('modify_key[]', 'annual_income');
//             if (selectedId > 3) {
//                 formData.append('doc_type', 'Income_Proof');
//                 formData.append('proof_type', selectProoftype);
//                 formData.append('doc_path', file);
//                 formData.append('doc_password', password || null);
//                 formData.append('annual_income_id', selectedId);
//             }

//             axios.post(MOD_EKYC_NEW + '/update-modification', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                     'Authorization': 'Bearer your_token', // Add any custom headers here
//                 },
//             })

//                 .then(response => {
//                     if (response.status == true) {
//                         setStaus(true)
//                         setEsignModal(true)
//                         setLoading(false)

//                     }
//                     else {
//                         toast(response.message || 'something went wrong')
//                         setStaus(true)
//                         setLoading(false)

//                     }

//                 })
//                 .catch(error => {

//                     toast.error("An error occurred during the update process.", {
//                         autoClose: 1500,
//                     });
//                     setLoading(false)

//                 });

//         }
//     }

//     const toggle = () => setEsignModal(!EsignModal)

//     const handlepay = () => {

//         const payload = {
//             ClientCode: client_code,
//             amount: Balance > 0 ? (25 - Balance) : (Math.abs(Balance) + 25),
//             redirectUrl: redirectApp,
//         };


//         const encryptedPayload = Encrypted([payload]);


//         window.location.href = 'https://payment.bigul.co/v1/?enc=' + encryptedPayload;
//     };
//     useEffect(() => {

//         let intervalId;

//         const callApi = () => {
//             checkModificationStatus({
//                 client_code: client_code,
//                 entry_type: 'annual_income',

//             })
//                 .then((response) => {
//                     if (response.status == true && response?.data?.modification?.length > 0) {
//                         if (response?.data?.modification[0]?.is_esign == 1) {
//                             setModificationData(response?.data?.modification[0])
//                             setShouldContinueApi(false)
//                         }

//                     } else {
//                         toast('something went wrong')
//                         // setShouldContinueApi(false)
//                     }
//                 })
//                 .catch((err) => {
//                     toast('something went wrong')

//                     //   setShouldContinueApi(false)
//                 });


//         };

//         if (props.activeTab === "7" && shouldContinueApi) {
//             intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
//         }

//         return () => {
//             clearInterval(intervalId); // Cleanup on unmount or condition change
//         };
//     }, [props.activeTab === "7", shouldContinueApi]);
//     const handleEsignConfirm = () => {

//         setBalance(false)
//         if (isNaN(Balance)) {
//             toast.error('Unable to fetch your ledger balance. Please try after some time.');
//             return;
//         }
//         if (Balance < 25) {
//             setBalance(true)
//         }
//         else {
//             setDeseble(true)
//             modificationEsign({
//                 client_code: client_code,
//                 entry_type: 'annual_income',
//             })
//                 .then((response) => {
//                     if (response?.status) {
//                         setDeseble(false)
//                         window.open(`${response?.redirectUrl}`, "_blank");
//                         setShouldContinueApi(true)
//                         setTimeout(() => {
//                             setEsignModal(false)
//                         }, 1000);
//                     }
//                     else {
//                         toast(response.message)
//                         setDeseble(false)
//                     }
//                 })
//                 .catch((err) => {
//                     setDeseble(false)
//                     toast('something went wrong')
//                 });
//         }


//     }
//     const handleEsign = (e) => {
//         // setEsignName(e)
//         setEsignModal(true)
//         setBalance(false)
//     }

//     const handleCancel = async () => {
//         setLoading(true)

//         try {
//             const payload = {
//                 client_code: client_code,
//                 entry_type: 'annual_income'
//             }; // Your POST data here
//             const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', { 'enc': Encrypted([payload]) });
//             if (response?.status == true) {


//                 setEsignModal(false)
//                 setBalance(false)
//                 setShouldContinueApi(false)
//                 setTimeout(() => {
//                     setLoading(false)
//                 }, 1000);

//             } else {
//                 setTimeout(() => {
//                     setLoading(false)
//                 }, 1000);

//                 toast.error('something went wrong please try again')
//             }
//         } catch (error) {
//             setLoading(false)
//             toast.error('something went wrong please try again')
//         }
//     }

//     if (loading) {
//         return <Loader />
//     }
//     return (
//         <div>

//             <Modal isOpen={EsignModal} toggle={toggle} >
//                 <ModalHeader toggle={toggle} >{balance ? 'Proceed to Pay' : 'Proceed to Esign'}</ModalHeader>

//                 <ModalBody>
//                     {
//                         balance ?
//                             <h4 className="text-9 text-color5 mt-3 text-center">
//                                 The amount available in your ledger balance is not not sufficient for this transaction.
//                             </h4>
//                             :
//                             <h4 className="text-9 text-color5 mt-3 text-center">
//                                 Please proceed to E-sign to confirm your request for modification. Rs. 25 (including GST) charge will be applicable for modification. The amount will be deducted from your ledger balance.
//                             </h4>
//                     }
//                 </ModalBody>
//                 <div className="d-flex justify-content-center">
//                     {
//                         balance ?
//                             <>
//                                 <button
//                                     type="button"
//                                     className="btn btn-primary modal-content-Button my-3"
//                                     onClick={handlepay}
//                                 >
//                                     {" "}
//                                     Proceed to pay
//                                 </button>
//                                 <button
//                                     type="button"
//                                     style={{
//                                         marginLeft: '10px',
//                                         backgroundColor: 'gray',
//                                         color: 'white',
//                                         border: '1px solid gray',
//                                         padding: '10px 20px',
//                                         borderRadius: '4px',
//                                         cursor: 'pointer'
//                                     }}
//                                     className="btn btn-primary modal-content-Button my-3"
//                                     onClick={handleCancel}
//                                 >
//                                     Cancel Request
//                                 </button>
//                             </>
//                             :
//                             <>
//                                 <button
//                                     type="button"
//                                     style={{ marginLeft: '10px' }}
//                                     className="btn btn-primary modal-content-Button my-3"
//                                     onClick={handleEsignConfirm}
//                                     disabled={deseble}

//                                 >
//                                     {" "}
//                                     {deseble ? 'please wait...' : 'Proceed to eSign'}

//                                 </button>
//                                 <button
//                                     type="button"
//                                     style={{
//                                         marginLeft: '10px',
//                                         backgroundColor: 'gray',
//                                         color: 'white',
//                                         border: '1px solid gray',
//                                         padding: '10px 20px',
//                                         borderRadius: '4px',
//                                         cursor: 'pointer'
//                                     }}
//                                     className="btn btn-primary modal-content-Button my-3"
//                                     onClick={handleCancel}
//                                 >
//                                     Cancel Request
//                                 </button>
//                             </>
//                     }
//                 </div>
//             </Modal>
//             {
//                 check &&
//                 <button
//                     type="button"
//                     className="additional-page-button-cancel me-2"
//                     onClick={() => {
//                         setCheck(false)
//                         setSelectedId(0);
//                     }}
//                 >
//                     Go Back
//                 </button>
//             }
//             <Col lg={4} xs={6} className="additional-switch">
//                 <div className="mt-2" style={{ width: '350px', display: 'flex', justifyContent: 'space-around', textAlign: 'center', alignItems: 'center' }}>
//                     <h2 className="text-4 text-color1">
//                         Annual Income
//                     </h2>
//                     <h2 className="text-4 text-color1">
//                         {modiData?.new_value == 1 ? 'Below 1 Lakh' : modiData?.new_value == 2 ? '1-5 Lakhs' : modiData?.new_value == 3 ? '5-10 Lakhs' : modiData?.new_value == 4 ? '10-25 Lakhs' : modiData?.new_value == 5 ? '25-100 Lakhs' : parseInt(data[0]?.NINCOME) <= 100000 ? 'Below 1 Lakh' : parseInt(data[0]?.NINCOME) <= 500000 ? '1-5 Lakhs' : parseInt(data[0]?.NINCOME) <= 1000000 ? '5-10 Lakhs' : parseInt(data[0]?.NINCOME) <= 2500000 ? '10-25 Lakhs' : '25-100 Lacks'}
//                     </h2>
//                     {
//                         modiData?.is_esign == 0 ? <p style={{ backgroundColor: "#5367FC", padding: '7px', borderRadius: 5, color: 'white', cursor: 'pointer' }} onClick={() => handleEsign()}>Esign Pending</p> :
//                             modiData?.is_send_to_ld == 1 ?
//                                 <p style={{ color: 'green' }}>In progress</p> :
//                                 modiData?.is_esign == 1 ?
//                                     <p className="text-5 text-color10 m-0"

//                                     >Pending verification</p> :

//                                     // <p style={{ cursor: 'pointer' }} onClick={() => handleEdit()} >Edit</p>
//                                     <button
//                                         className="text-11 text-color7 profile-edit-button1"
//                                         onClick={() => handleEdit()}
//                                     >
//                                         Edit
//                                     </button>

//                     }
//                 </div>
//             </Col>
//             {
//                 check &&
//                 <Row className="mb-3 mt-5">
//                     <Col lg={3}>
//                         <Label
//                             htmlFor="nameInput"
//                             className="py-2 m-0 text-101 text-color5"
//                         >
//                             Annual Income
//                         </Label>
//                     </Col>
//                     <Col lg={6}>
//                         <Input
//                             className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                             id="incomeSelect"
//                             type="select"
//                             value={selectedValue}
//                             onChange={handleSelectChange}
//                         >
//                             <option value="">
//                                 Select annual income
//                             </option>
//                             <option value="Below 1 Lakh">
//                                 Below 1 Lakh
//                             </option>
//                             <option value="1-5 Lakhs">
//                                 1-5 Lakhs
//                             </option>
//                             <option value="5-10 Lakhs">
//                                 5-10 Lakhs
//                             </option>
//                             <option value="10-25 Lakhs">
//                                 10-25 Lakhs
//                             </option>
//                             <option value="25-100 Lakhs">
//                                 25-100 Lakhs
//                             </option>
//                         </Input>
//                     </Col>
//                 </Row>
//             }
//             {
//                 selectedId > 3 &&
//                 <>
//                     <p style={{ textAlign: 'center', color: 'red' }}>Proof of income will  be required, since your annual income is exceeding Rs 10 Lakhs.</p>
//                     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
//                         <Row >

//                             {<Input
//                                 className="nominee-form-input text-color1 text-4 nominee-form-input-select"
//                                 id="nameInput"
//                                 type="select"
//                                 name="proof_type"
//                                 value={selectProoftype}
//                                 onChange={handleSelectProof}

//                             >
//                                 <option key="0" value="" disabled>
//                                     Select proof type
//                                 </option>
//                                 <option key="1" value="ITR Copy">
//                                     ITR Copy
//                                 </option>
//                                 <option key="2" value="Audited Balancesheet">
//                                     Audited balance sheet
//                                 </option>
//                                 <option key="3" value="Form 16">
//                                     Form 16
//                                 </option>
//                                 <option key="4" value="Latest net worth certificate">
//                                     Latest net worth certificate
//                                 </option>
//                                 <option key="5" value="Last Month's Salary Slip">
//                                     Last month's salary slip
//                                 </option>
//                                 <option
//                                     key="6"
//                                     value="Bank Account Statement for last 6 months"
//                                 >
//                                     Bank account statement for last 6 months
//                                 </option>
//                                 <option key="7" value="Demat Holding Statement">
//                                     Demat holding statement
//                                 </option>
//                                 <option key="8" value="Other investment Proof">
//                                     Other investment proof
//                                 </option>
//                             </Input>}

//                             {<Input
//                                 className="nominee-form-input text-color1 text-4 mt-3"
//                                 type="file"
//                                 id="formFile"
//                                 name="proof_file"
//                                 onChange={handleFileChange}

//                             />}
//                             {<Input
//                                 className="nominee-form-input text-color1 text-4 mt-3"
//                                 type="text"
//                                 placeholder="Enter File Password"
//                                 name="file_password"
//                                 value={password}
//                                 onChange={(e) => setPasword(e.target.value)}
//                                 maxLength={20}
//                             // onChange={handleFileChange}

//                             />}
//                         </Row>
//                     </div>

//                 </>

//             }
//             {
//                 check &&
//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                     <button
//                         type="button"
//                         className="btn btn-primary modal-content-Button my-3"
//                         onClick={handleSubmit}
//                     >
//                         Confirm
//                     </button>
//                 </div>
//             }

//         </div>
//     )
// }

// export default AnnualIncome


import { useEffect, useState } from "react";
import { SymphonyWithdrawalBalance, checkModificationStatus, esignPayment, modificationEsign } from "../../helpers/fakebackend_helper";
import { Col, Input, Row, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Alignment } from "react-data-table-component";
import { toast } from "react-toastify";
import { logDOM } from "@testing-library/react";
import axios from "axios";
import { MOD_EKYC_NEW, redirectApp } from "../../helpers/url_helper";
import Encrypted from "../Dashboard/Encripted";
import Loader from "../../Components/Common/Loader";


const AnnualIncome = (props) => {
    const { data, Balance } = props;
    const client_code = localStorage.getItem("client_id");
    const client_token = localStorage.getItem("client_token");
    const client_type = localStorage.getItem("client_type");
    const [modiData, setModificationData] = useState()
    const [check, setCheck] = useState(false)
    const [status, setStaus] = useState(false)
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [selectProoftype, setSelectProofType] = useState('');
    const [file, setFile] = useState(null);
    const [EsignModal, setEsignModal] = useState(false);



    const [shouldContinueApi, setShouldContinueApi] = useState(false);
    const symphony_token = localStorage.getItem("symphony_token");
    const [password, setPasword] = useState('')
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        // setShouldContinueApi(false)
    }, [props.activeTab === "7"])
    useEffect(() => {
        // setShouldContinueApi(false)

        if (props.activeTab === "7") {
            setSelectedValue('')
            setSelectedId('')
            setSelectProofType("")
            setCheck(false)
            setFile(null)
            setStaus(false)
            checkModificationStatus({
                client_code: client_code,
                entry_type: "annual_income",

            })
                .then((response) => {
                    if (response.status == true && response?.data?.modification?.length > 0) {
                        setModificationData(response?.data?.modification[0])
                    }
                    else {
                        setModificationData({})
                    }
                })
                .catch((err) => {
                });
        }
    }, [data, status, EsignModal]);
    useEffect(() => {
        setLoading(true)

        if (props.activeTab === "7") {
            setSelectedValue('')
            setSelectedId('')
            setSelectProofType("")
            setCheck(false)
            setFile(null)
            setStaus(false)
            checkModificationStatus({
                client_code: client_code,
                entry_type: "annual_income",

            })
                .then((response) => {
                    setLoading(false)

                    if (response.status == true && response?.data?.modification?.length > 0) {
                        setModificationData(response?.data?.modification[0])
                    }
                    else {
                        setModificationData({})
                    }
                })
                .catch((err) => {
                    setLoading(false)

                });
        }
    }, [props.activeTab === "7"]);

    const handleEdit = () => {
        setCheck(true)
    }
    const handleSelectChange = (e) => {
        const value = e.target.value
        setSelectedValue(value)
        let id = '';
        switch (value) {
            case 'Below 1 Lakh':
                id = '1';
                break;
            case '1-5 Lakhs':
                id = '2';
                break;
            case '5-10 Lakhs':
                id = '3';
                break;
            case '10-25 Lakhs':
                id = '4';
                break;
            case '25-100 Lakhs':
                id = '5';
                break;
            default:
                id = '';
        }
        setSelectedId(id);
    }

    const handleSelectProof = (e) => {
        const value = e.target.value
        setSelectProofType(value)
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async () => {
        const allowedFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
        const maxFileSize = 2 * 1024 * 1024;
        if (selectedId > 3 && selectProoftype == '') {
            toast.error('please select proof type')
            return
        }
        if (selectedId > 3 && file == null) {
            toast.error('please select document')
            return
        }
        if (selectedId > 3 && !allowedFileTypes.includes(file.type)) {
            toast.error('Invalid file type. Only PDF, PNG, and JPEG are allowed.');
            return;
        }

        if (selectedId > 3 && file.size > maxFileSize) {
            toast.error('File size exceeds 2 MB.');
            return;
        }
        else {
            setLoading(true)
            const formData = new FormData();
            formData.append('client_code', client_code);
            formData.append('client_token', client_token);
            formData.append('client_type', client_type);
            formData.append('entry_type', 'annual_income');
            formData.append('old[annual_income]', null);
            formData.append('new[annual_income]', selectedId);

            formData.append('modify_key[]', 'annual_income');
            if (selectedId > 3) {
                formData.append('doc_type', 'Income_Proof');
                formData.append('proof_type', selectProoftype);
                formData.append('doc_path', file);
                formData.append('doc_password', password || null);
                formData.append('annual_income_id', selectedId);
            }

            axios.post(MOD_EKYC_NEW + '/update-modification', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer your_token', // Add any custom headers here
                },
            })

                .then(response => {
                    if (response.status == true) {
                        setStaus(true)
                        // setEsignModal(true)
                        setLoading(false)

                    }
                    else {
                        toast(response.message || 'something went wrong')
                        setStaus(true)
                        setLoading(false)

                    }

                })
                .catch(error => {

                    toast.error("An error occurred during the update process.", {
                        autoClose: 1500,
                    });
                    setLoading(false)

                });

        }
    }

    // const toggle = () => setEsignModal(!EsignModal)

    // const handlepay = () => {

    //     const payload = {
    //         ClientCode: client_code,
    //         amount: Balance > 0 ? (25 - Balance) : (Math.abs(Balance) + 25),
    //         redirectUrl: redirectApp,
    //     };


    //     const encryptedPayload = Encrypted([payload]);


    //     window.location.href = 'https://payment.bigul.co/v1/?enc=' + encryptedPayload;
    // };
    // useEffect(() => {

    //     let intervalId;

    //     const callApi = () => {
    //         checkModificationStatus({
    //             client_code: client_code,
    //             entry_type: 'annual_income',

    //         })
    //             .then((response) => {
    //                 if (response.status == true && response?.data?.modification?.length > 0) {
    //                     if (response?.data?.modification[0]?.is_esign == 1) {
    //                         setModificationData(response?.data?.modification[0])
    //                         setShouldContinueApi(false)
    //                     }

    //                 } else {
    //                     toast('something went wrong')
    //                     // setShouldContinueApi(false)
    //                 }
    //             })
    //             .catch((err) => {
    //                 toast('something went wrong')

    //                 //   setShouldContinueApi(false)
    //             });


    //     };

    //     if (props.activeTab === "7" && shouldContinueApi) {
    //         intervalId = setInterval(callApi, 20000); // Call API every 10 seconds
    //     }

    //     return () => {
    //         clearInterval(intervalId); // Cleanup on unmount or condition change
    //     };
    // }, [props.activeTab === "7", shouldContinueApi]);
    // const handleEsignConfirm = () => {

    //     setBalance(false)
    //     if (isNaN(Balance)) {
    //         toast.error('Unable to fetch your ledger balance. Please try after some time.');
    //         return;
    //     }
    //     if (Balance < 25) {
    //         setBalance(true)
    //     }
    //     else {
    //         setDeseble(true)
    //         modificationEsign({
    //             client_code: client_code,
    //             entry_type: 'annual_income',
    //         })
    //             .then((response) => {
    //                 if (response?.status) {
    //                     setDeseble(false)
    //                     window.open(`${response?.redirectUrl}`, "_blank");
    //                     setShouldContinueApi(true)
    //                     setTimeout(() => {
    //                         setEsignModal(false)
    //                     }, 1000);
    //                 }
    //                 else {
    //                     toast(response.message)
    //                     setDeseble(false)
    //                 }
    //             })
    //             .catch((err) => {
    //                 setDeseble(false)
    //                 toast('something went wrong')
    //             });
    //     }


    // }
    // const handleEsign = (e) => {
    //     // setEsignName(e)
    //     setEsignModal(true)
    //     setBalance(false)
    // }

    // const handleCancel = async () => {
    //     setLoading(true)

    //     try {
    //         const payload = {
    //             client_code: client_code,
    //             entry_type: 'annual_income'
    //         }; // Your POST data here
    //         const response = await axios.post(MOD_EKYC_NEW + '/delete-modification', { 'enc': Encrypted([payload]) });
    //         if (response?.status == true) {


    //             setEsignModal(false)
    //             setBalance(false)
    //             setShouldContinueApi(false)
    //             setTimeout(() => {
    //                 setLoading(false)
    //             }, 1000);

    //         } else {
    //             setTimeout(() => {
    //                 setLoading(false)
    //             }, 1000);

    //             toast.error('something went wrong please try again')
    //         }
    //     } catch (error) {
    //         setLoading(false)
    //         toast.error('something went wrong please try again')
    //     }
    // }

    if (loading) {
        return <Loader />
    }
    return (
        <div>


            {
                data[0]?.NINCOME <= 0 &&
                <p style={{ marginBottom: '30px', textAlign: 'center', color: 'red' }}>Your annual income details for the current year have not been updated in our records. To proceed with any other modification requests, please update your annual income information first.</p>
            }
            {
                check &&
                <button
                    type="button"
                    className="additional-page-button-cancel me-2"
                    onClick={() => {
                        setCheck(false)
                        setSelectedId(0);
                    }}
                >
                    Go Back
                </button>
            }
            {
                !check &&
                <Col lg={4} xs={12} className="additional-switch">

                    <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-around', textAlign: 'center', alignItems: 'center' }}>
                        <h2 className="text-4 text-color1">
                            Annual Income
                        </h2>
                        <h2 className="text-4 text-color1">
                            {modiData?.new_value == 1 ? 'Below 1 Lakh' : modiData?.new_value == 2 ? '1-5 Lakhs' : modiData?.new_value == 3 ? '5-10 Lakhs' : modiData?.new_value == 4 ? '10-25 Lakhs' : modiData?.new_value == 5 ? '25-100 Lakhs' : parseInt(data[0]?.NINCOME) <= 100000 ? 'Below 1 Lakh' : parseInt(data[0]?.NINCOME) <= 500000 ? '1-5 Lakhs' : parseInt(data[0]?.NINCOME) <= 1000000 ? '5-10 Lakhs' : parseInt(data[0]?.NINCOME) <= 2500000 ? '10-25 Lakhs' : '25-100 Lacks'}
                        </h2>
                        {
                            modiData?.is_send_to_ld == 1 ?
                                <p style={{ color: 'green' }}>In progress</p> :
                                // modiData?.is_esign == 0 ? <p style={{ backgroundColor: "#5367FC", padding: '7px', borderRadius: 5, color: 'white', cursor: 'pointer' }} onClick={() => handleEsign()}>Esign Pending</p> :

                                (modiData?.is_esign == 0 || modiData?.is_esign == 1) ?
                                    <p className="text-5 text-color10 m-0"

                                    >Pending verification</p> :

                                    // <p style={{ cursor: 'pointer' }} onClick={() => handleEdit()} >Edit</p>
                                    <button
                                        className="text-11 text-color7 profile-edit-button1"
                                        onClick={() => handleEdit()}
                                    >
                                        Edit
                                    </button>

                        }
                    </div>
                </Col>
            }

            {
                check &&
                <Row className="mb-3 mt-5">
                    <Col lg={3} style={{ marginLeft: '20px' }}>
                        <Label
                            htmlFor="nameInput"
                            className="py-2 m-0 text-101 text-color5"
                        >
                            Annual Income
                        </Label>
                    </Col>
                    <Col lg={6}>
                        <Input
                            className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                            id="incomeSelect"
                            type="select"
                            value={selectedValue}
                            onChange={handleSelectChange}
                        >
                            <option value="">
                                Select annual income
                            </option>
                            <option value="Below 1 Lakh">
                                Below 1 Lakh
                            </option>
                            <option value="1-5 Lakhs">
                                1-5 Lakhs
                            </option>
                            <option value="5-10 Lakhs">
                                5-10 Lakhs
                            </option>
                            <option value="10-25 Lakhs">
                                10-25 Lakhs
                            </option>
                            <option value="25-100 Lakhs">
                                25-100 Lakhs
                            </option>
                        </Input>
                    </Col>
                </Row>
            }
            {
                selectedId > 3 &&
                <>
                    <p style={{ textAlign: 'center', color: 'red' }}>Proof of income will  be required, since your annual income is exceeding Rs 10 Lakhs.</p>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Row >

                            {<Input
                                className="nominee-form-input text-color1 text-4 nominee-form-input-select"
                                id="nameInput"
                                type="select"
                                name="proof_type"
                                value={selectProoftype}
                                onChange={handleSelectProof}

                            >
                                <option key="0" value="" disabled>
                                    Select proof type
                                </option>
                                <option key="1" value="ITR Copy">
                                    ITR Copy
                                </option>
                                <option key="2" value="Audited Balancesheet">
                                    Audited balance sheet
                                </option>
                                <option key="3" value="Form 16">
                                    Form 16
                                </option>
                                <option key="4" value="Latest net worth certificate">
                                    Latest net worth certificate
                                </option>
                                <option key="5" value="Last Month's Salary Slip">
                                    Last month's salary slip
                                </option>
                                <option
                                    key="6"
                                    value="Bank Account Statement for last 6 months"
                                >
                                    Bank account statement for last 6 months
                                </option>
                                <option key="7" value="Demat Holding Statement">
                                    Demat holding statement
                                </option>
                                <option key="8" value="Other investment Proof">
                                    Other investment proof
                                </option>
                            </Input>}

                            {<Input
                                className="nominee-form-input text-color1 text-4 mt-3"
                                type="file"
                                id="formFile"
                                name="proof_file"
                                onChange={handleFileChange}

                            />}
                            {<Input
                                className="nominee-form-input text-color1 text-4 mt-3"
                                type="text"
                                placeholder="Enter File Password"
                                name="file_password"
                                value={password}
                                onChange={(e) => setPasword(e.target.value)}
                                maxLength={20}
                            // onChange={handleFileChange}

                            />}
                        </Row>
                    </div>

                </>

            }
            {
                check &&
                <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                        type="button"
                        className="btn btn-secondry modal-content-Button my-3"
                        onClick={()=>setCheck(false)}
                    >
                        Cancel
                    </button>
                </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            type="button"
                            className="btn btn-primary modal-content-Button my-3"
                            onClick={handleSubmit}
                            disabled={selectedId == ''}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            }

        </div>
    )
}

export default AnnualIncome