import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
//Import actions
import { getMyPlan } from "../../store/myPlan/action";
//Import actions
import { getMyPlanDuration } from "../../store/myPlanDuration/action";
//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";
import MyPlansHeader from "./MyPlansHeader";
import Features from "./Features";
import AvailableFeatureHeader from "./AvailableFeatureHeader";
import AvailableFeatures from "./AvailableFeatures";
import MyPlansFooter from "./MyPlansFooter";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const MySubscriptionPlans = () => {
  // document.title = "Bigul Admin & Dashboard";

  const dispatch = useDispatch();
  const search = useLocation().search;

  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  var client_code = localStorage.getItem("client_id");
  var client_token = localStorage.getItem("client_token");
  // if(client_code == undefined || client_code == 'NULL' || client_code == "" || client_token == undefined || client_token == 'NULL' || client_token == ""){

  //   var client_code = new URLSearchParams(search).get('client_id');
  //   const token = new URLSearchParams(search).get('token');
  //   const symphony_token = new URLSearchParams(search).get('symphony_token');
  //   // console.log(token)
  //   let decodedStringAtoB = atob(client_code);
  //   let decodedStringAtoc = atob(token); // TODO seve to client_token
  //   let decodedStringAtod = atob(symphony_token); // TODO seve to client_token

  //     localStorage.setItem('client_id', decodedStringAtoB);
  //     localStorage.setItem('client_token', decodedStringAtoc); // TODO seve to client_token
  //     localStorage.setItem('symphony_token', decodedStringAtod); // TODO seve to client_token
  //     var client_code =decodedStringAtoB;
  //     var client_token =decodedStringAtoc;
  // }

  const { myPlan } = useSelector((state) => ({
    myPlan: state.MyPlan.myPlan,
  }));

   const client_type = localStorage.getItem('client_type');
  const navigate=useNavigate()
  
  
     useEffect(()=>{
      if(client_type == 'Bonanza'){
        navigate('/')
  
      }
      
    },[client_type])

  const { myPlanDuration } = useSelector((state) => ({
    myPlanDuration: state.MyPlanDuration.myPlanDuration,
  }));
  console.log("Subscription Client Code = ", client_code);
  useEffect(() => {
    dispatch(
      getMyPlan({ client_code: client_code, client_token: client_token })
    );
    dispatch(
      getMyPlanDuration({
        client_code: client_code,
        client_token: client_token,
      })
    );
  }, [dispatch, client_code, client_token]);

  const client_id_check = new URLSearchParams(search).get("client_id");
  if (
    client_id_check == undefined ||
    client_id_check == "NULL" ||
    client_id_check == ""
  ) {
    var ii = 0;
  } else {
    return <Navigate to={{ pathname: "/subscription/plans" }} />;
  }

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Plans"
            titleMain="Plans"
            //titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to="/dashboard"
          />
          <Row className="my-3">
            <MyPlansHeader />
            {/* <NeedHelp /> */}
          </Row>
          <Row>
            {myPlan && myPlan.plan && myPlan.plan.length > 0 && (
              <Features data={myPlan} />
            )}
            {/* <Features data={myPlan} /> */}
          </Row>
          <Row>
            <AvailableFeatureHeader />
          </Row>
        </Container>
        <Row className="px-4 justify-content-center">
          <AvailableFeatures data={myPlanDuration} myPlan={myPlan.plan} />
        </Row>
        <Row className="mt-4 mb-5">
          <MyPlansFooter />
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MySubscriptionPlans;
