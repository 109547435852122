
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isExplore, setIsExplore] = useState(false);
    const [isMyAlgos, setIsMyAlgos] = useState(false);
    const [isPortfolio, setIsPortfolio] = useState(false);
    const [isStatements, setIsStatements] = useState(false);
    const [isMore, setIsMore] = useState(false);
  

    const [iscurrentState, setIscurrentState] = useState('Dashboard');
    var client_type = localStorage.getItem('client_type');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'ReferEarn') {
            setIsExplore(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Explore') {
            setIsExplore(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'MyAlgos') {
            setIsMyAlgos(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Portfolio') {
            setIsPortfolio(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Statements') {
            setIsStatements(false);
            document.body.classList.remove("menu")
        }
        if (iscurrentState !== 'Profile') {
            setIsMore(false);
            document.body.classList.remove("menu")
        }
       
    }, [
        history,
        iscurrentState,
        isDashboard,
        isExplore,
        isMyAlgos,
        isPortfolio,
        isStatements,
        isMore
    ]);


   

    const showReferEarn =  false; // This is the condition. Set to true if you want to show the item, false to hide it.

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-home-6-line",
            link: "/dashboard",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            }
        },
        // Conditionally add "Refer & Earn"
        ...(showReferEarn ? [{
            id: "referEarn",
            label: "Refer & Earn",
            icon: "ri-compass-3-line",
            link: "/refer-and-earn",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('referEarn');
                updateIconSidebar(e);
            }
        }] : []),
        {
            id: "statements",
            label: "Statements",
            icon: "ri-file-text-line",
            link: "/statements",
            stateVariables: isStatements,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isStatements);
                setIscurrentState('Statements');
                updateIconSidebar(e);
            }
        },
        {
            id: "profile",
            label: "Profile",
            icon: "ri-add-circle-line",
            link: "/profile",
            stateVariables: isMore,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isMore);
                setIscurrentState('Profile');
                updateIconSidebar(e);
            }
        },
        {
            id: "Family Declaration",
            label: "Family Declaration",
            icon: "ri-group-line",
            link: "/family",
            stateVariables: isMore,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isMore);
                setIscurrentState('Family Declaration');
                updateIconSidebar(e);
            }
        },
    ];


    return <React.Fragment>{menuItems}</React.Fragment>;
    // return <React.Fragment>{ menuItems}</React.Fragment>;
};
export default Navdata;