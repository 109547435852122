import React, { useState } from "react";
//redux
import { useSelector } from "react-redux";

import { Card, Col, Container, Row } from "reactstrap";

//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

import MarginOverview from "./MarginOverview";
import Ledger from "../MyStatements/Ledger";
import BreakDown from "./BreakDown";

const MarginOverView = () => {
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));

  const generatedKey = JSON.parse(localStorage.getItem("genApiKeyResult"));
  const encryptKey = JSON.parse(localStorage.getItem("encryptKey"));

  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Margin Overview"
            titleMain="Margin Overview"
            pageTitle="Dashboard"
            to="/dashboard"
          />
          <Row>
           
            <Col >
              <BreakDown
                data={profileDetails[0]}
                generatedKey={generatedKey}
                encryptKey={encryptKey}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="">
                <Ledger generatedKey={generatedKey} encryptKey={encryptKey} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MarginOverView;
