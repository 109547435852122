
import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import * as moment from "moment";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import DeleteModal from "../../Components/Common/DeleteModal";
import Loader from "../../Components/Common/Loader";
import {
  contractNoteMonthlyApi,
  contractNoteDownloadApi,
} from "../../helpers/fakebackend_helper";
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer2 from "../../Components/CustomComponents/TableContainer2";

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

const ContractNote = (props) => {
  var customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");




  // Customers Column
  const columns = useMemo(
    () => [
      // {
      //   //TODO
      //   Header: "#",
      //   Cell: (cell) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="productCheckBox form-check-input"
      //         value={cell.row.original._id}
      //         //onClick={() => displayDelete()}
      //       />
      //     );
      //   },
      // },
      {
        Header: "Date",
        accessor: "Tdate",
        filterable: true,
        Cell: (cell) => {
          return dayjs(cell.value).format(" DD MMM YYYY");
        },
      },
      // {
      //   Header: "File Name",
      //   accessor: "Filename",
      //   filterable: true,
      // },
      {
        Header: "Action",
        Cell: (cellProps) => {
       
          const dateString = cellProps.data[0].Tdate
          const date = new Date(dateString)
          const year = date.getFullYear();
         return (
            <>
              {
                year > 2019 ?
                  <Link
                    to="#"
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => downloadFileHandler(cellProps.row.original)}
                  >
                    <i className="ri-file-download-line fs-5"></i>
                  </Link>
                  :
                  <p><a href="mailto:support@bigul.co" target="_blank">
                  Contact Bigul Support
                </a></p>

              }
 </>

          );
        },
      },
    ],
    []
  );

  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [note, setNote] = useState([]);
  const startDate = new Date();
  const [selectDate, setSelectDate] = useState(startDate);


  ////console.log(note);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let month =
    selectDate.length > 0
      ? months[selectDate[0].getMonth()]
      : months[selectDate.getMonth()];
  let year =
    selectDate.length > 0
      ? selectDate[0].getFullYear()
      : selectDate.getFullYear();
  const myYear = `${month} ${year}`;
  // //console.log(selectDate)
  console.log('monthmonthmonthmonth', month);


  useEffect(() => {
    var currentYear = new Date().getFullYear();
    var current_year = '';
    if (year == currentYear) {
      const currentYearlast = new Date().getFullYear().toString().slice(-2)-1;
      const currentYearplus = parseInt(currentYearlast) + parseInt(1);
      var ddyr_send = currentYearlast + '-' + currentYearplus;
      console.log('currentYearpluscurrentYearpluscurrentYearplus', ddyr_send);
      if (month == 'January' || month == 'February' || month == 'March') {
        localStorage.setItem('ddyr_send', '24-25');
      } else {
        localStorage.setItem('ddyr_send', ddyr_send);
      }
    } else {
      var ddyr_send = '24-25';
      localStorage.setItem('ddyr_send', '24-25');
    }
    console.log('ddyr_sendddyr_sendddyr_send', ddyr_send);


    contractNoteMonthlyApi({
      apiKey: props.generatedKey,
      code: props.encryptKey,
      year: myYear,
      ddyr_send: ddyr_send,
    })
      .then((response) => {
        ////console.log(response);
        setNote(response.contractnewResult);
        // setIsDownload(true);
      })
      .catch((err) => {
        ////console.log(err);
      });


  }, [myYear, selectDate]);

  const downloadFileHandler = (data) => {
    // alert(props.generatedKey);
    // alert(data.Filename);
    ////console.log(data);
    //dispatch(contractNoteDownloadApi({apiKey: props.generatedKey, fileName: data.Filename}));
    // contractNoteDownloadApi({apiKey: props.generatedKey, fileName: data.Filename})
    //     .then((response) => {
    //       //console.log(response);
    //       //setNote(response.contractnewResult)
    //       // setIsDownload(true);
    //     })
    //     .catch((err) => {
    //       ////console.log(err);
    //     });

    var ddyr_set = localStorage.getItem('ddyr_send');
    // alert(ddyr_set);
    // console.log('ddyr_setddyr_setddyr_set', `http://124.153.94.35:3030/Bonanza_API/CLSqlInterface.svc/DownloadFile?apikey=${props.generatedKey}&Filename=${data.Filename}&dbyr=` + ddyr_set)
      window.open(
        `https://clientlevel.bonanzaonline.com:3031/Bonanza_API/CLSqlInterface.svc/DownloadFile?apikey=${props.generatedKey}&Filename=${data.Filename}&dbyr=` + ddyr_set,
        "_blank"
      );   

  };

   


















  // const updateCartHandler = (e) => {

  //   //console.log(e.target.value);

  //   taxDetailsApi({ id: id.id, code: client_code, token: client_token })
  //     .then((response) => {
  //       //console.log(response);
  //       if (response && response.status === true) {
  //         setListReport(response.data);
  //         //setTaxState({ taxList: true });
  //       }
  //     })
  //     .catch((err) => {
  //       ////console.log(err);
  //     });
  // };
  ////console.log(note)

  note.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    ////console.log(new Date(dayjs(a.dtoftran).format(" DD MMM YYYY")))
    return new Date(dayjs(b.Tdate).format(" DD MMM YYYY")) - new Date(dayjs(a.Tdate).format(" DD MMM YYYY"));
  });


  return (
    <React.Fragment>
      {/* <div>
        <div className="d-flex justify-content-between justify-content-center p-3">
          <div className="form-group">
            <DatePicker
              id="exampleInputdate"
              showIcon
              selected={selectDate}
              maxDate={new Date()}
              onChange={(date) => setSelectDate(date)}
              dateFormat="MMM-yyyy"
              showMonthYearPicker
              className='month-picker'
            />
          </div>
        </div>
      </div> */}
      <div className="custom-border2">
        <div className="d-flex justify-content-between justify-content-center p-3 mobile-statement-input-center">
          <div className="d-flex  align-items-center w-75 mobile-display-block">
            <h4 className="mb-0 me-4 text-10 text-center-statement">Select Month</h4>
            <div className="form-group">
              <DatePicker
                id="exampleInputdate"
                showIcon
                selected={selectDate}
                maxDate={new Date()}
                onChange={(date) => setSelectDate(date)}
                dateFormat="MMM-yyyy"
                showMonthYearPicker
                className='month-picker'
              />
            </div>
          </div>
        </div>
      </div>
      {note.length ? (
        <TableContainer2
          columns={columns}
          data={note.reverse() || []}
          isGlobalFilter={true}
          isAddUserList={false}
          customPageSize={8}
          divClass="table-responsive table-card mb-1"
          tableClass="align-middle table-nowrap text-center"
          theadClass="table-light text-muted text-uppercase fs-13"
          tableTrClasses="summary-table-tr px-4"
          // handleOrderClick={handleCustomerClicks}
          isOrderFilter={true}
          SearchPlaceholder="Search for order ID, customer, order status or something..."
        />
      ) : (
        <div className="d-flex justify-content-center mx-2 mt-4 mb-4 fs-4"><p className="m-0">No records found!</p></div>
      )}
    </React.Fragment>
  );
};

export default ContractNote;
