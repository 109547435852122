import React, { useState, useEffect } from "react";
import logo from '../../assets/images/mo-wallet.svg'
import logo1 from '../../assets/images/mo-collaterl.svg'
import logo2 from '../../assets/images/mo-payin.svg'
import logo3 from '../../assets/images/mo-payout.svg'
import { getCashbalance_new, getCashbalance_new1, getCollateral_new, getPayout_new, SymphonyWithdrawalBalance } from "../../helpers/fakebackend_helper";
import loaderImage from "../../assets/images/Loader.png";
import { toast } from "react-toastify";
import { auto } from "@popperjs/core";

const CreateBox = (props) => {
  return (

    <div className="dashboard-widget-bottom-margin col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">

      <div className="card-invest card ">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title text-primary rounded-circle fs-1 d-flex align-items-center justify-content-center" style={{ background: props?.background }}>
                <div class="card-invest-div-logo1 rounded-circle align-middle" >
                  <img src={props?.img} style={{ width: '22px', height: auto }} />

                </div>
              </span>
            </div>
            {
              props?.loading ?
                <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '50px' }}>
                  <img src={loaderImage} className="custom-loader" style={{ height: "20px", }} />
                </div>
                :
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex justify-content-between">
                    <p className="text-uppercase text-1 mb-1 text-color1">{props?.label}</p>
                  </div>
                  <h4 className="mb-0 text-2 text-color1">{props.amount}</h4>
                </div>
            }
          </div>
        </div>
      </div>
    </div>

  );
};

const BreakDown = (props) => {
  const { generatedKey, encryptKey } = props;
  const [cashBalance, setcashBalance] = useState(0);
  const [collatral, setcollatral] = useState(0);
  const [marginUsed, setmarginUsed] = useState(0);
  const [payout, setPayout] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const client_type = localStorage.getItem('client_type');


  useEffect(() => {
    setLoading(true);
    getCollateral_new(client_type)
      .then((response) => {
        if (response?.status) {
          const formatCollateral = parseFloat(client_type == "Bigul" ? response?.data : response?.data?.total_collateral_amount).toFixed(2);
          setcollatral(formatCollateral);
        } else {
          setcollatral(0);
        }
      })
      .catch((err) => {
        setcollatral(0);
        setLoading(false);

      })
      .finally(() => {
        setLoading(false);
      });
  }, [generatedKey, encryptKey]);


  useEffect(() => {
    setLoading1(true);
    getPayout_new()
      .then((response) => {
        if (response?.status) {
          const formattedPayout = parseFloat(response?.data).toFixed(2);
          setPayout(formattedPayout);
        } else {
          setPayout(0);
        }
      })
      .catch((err) => {
        setPayout(0);
        setLoading1(false);

      })
      .finally(() => {
        setLoading1(false);
      });
  }, [generatedKey, encryptKey]);


  useEffect(() => {
    if (client_type == 'Bigul') {
      setLoading2(true);
      getCashbalance_new()
        .then((response) => {
          if (response?.status) {
            const check = parseFloat(response?.total_amount).toFixed(2);
            const payIn = parseFloat(response?.pay_in_amount).toFixed(2);
            setcashBalance(check);
            setmarginUsed(payIn);
          } else {
            setcashBalance(0);
            setmarginUsed(0);
          }
        })
        .catch((err) => {
          setcashBalance(0);
          setmarginUsed(0);
          setLoading2(false);

        })
        .finally(() => {
          setLoading2(false);
        });
    }
    else {
      setLoading2(true);
      getCashbalance_new1()
        .then((response) => {
          if (response?.status) {
            const check = parseFloat(response?.data.bod_amount).toFixed(2);
            const payIn = parseFloat(response?.data.payin_amount).toFixed(2);
            setcashBalance(check);
            setmarginUsed(payIn);
          } else {
            setcashBalance(0);
            setmarginUsed(0);
          }
        })
        .catch((err) => {
          setcashBalance(0);
          setmarginUsed(0);
          setLoading2(false);

        })
        .finally(() => {
          setLoading2(false);
        });
    }
  }, [generatedKey, encryptKey]);

  return (
    <div className="row">
      <CreateBox
        label={'Cash balance'}
        amount={cashBalance}
        loading={loading2}
        img={logo}
        background={'rgb(83, 103, 252'}
      />
      <CreateBox label={'Collateral margin'}
        amount={collatral}
        loading={loading}
        img={logo1}
        background={'rgb(0, 132, 144)'}
      />
      <CreateBox
        label={'Payin Amount'}
        amount={marginUsed}
        loading={loading1}
        img={logo2}
        background={'rgb(10, 50, 148)'}
      />
      <CreateBox
        label={`Today's Withdrawal`}
        amount={payout}
        loading={loading2}
        img={logo3}
        background={'#05cd99'} />
    </div>
  );
};

export default BreakDown;


