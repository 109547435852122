import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
//reuseable component
import BreadCrumb from "../../Components/CustomComponents/BreadCrumb";

import { isEmpty } from "lodash";

//Dashboard components
import MyPortfolio from "./MyPortfolio";
import MarginOverview from "./MarginOverview";
import Widgets from "./Widgets";
import Highlights from "./Highlights";
import MyInvestedAlgos from "./MyInvestedAlgos";
import SuggestedAlgos from "./SuggestedAlgos";
import ActiveFeatures from "./ActiveFeatures";
import ELearningSlider from "./ELearningSlider";
import AddonsSlider from "./AddonsSlider";
import Ambassador from "./Ambassador";
import FooterText from "./FooterText";
import ActiveFeatures1 from './ActiveFeatures1';
import SuggestedAlgos1 from './SuggestedAlgos1';
import MyPortfolio1 from './MyPortfolio1';
import Loader from '../../Components/Common/Loader';
import axios from "axios";

// IMAGE

const BigulDashboard = () => {
  document.title = "Bigul Admin & Dashboard";
  const { profileDetails } = useSelector((state) => ({
    profileDetails: state.ProfileDetails.userProfile,
  }));
  const [loading, setLoading] = useState(true);
  const [optOut, setOptOut] = useState('');
  const [optOut1, setOptOut1] = useState(false);
  const search = useLocation().search;

  const generatedKey = JSON.parse(localStorage.getItem('genApiKeyResult'));
  const encryptKey = JSON.parse(localStorage.getItem('encryptKey'));
  const client_type = localStorage.getItem("client_type");
  const client_code = localStorage.getItem("client_id");

  // const expires = localStorage.getItem("expires");

  const client_id_check = new URLSearchParams(search).get('client_id');
  const url1 = new URLSearchParams(search).get('redirect_back');
  const url = url1?.split('?')[0]
  //live
  const baseUrl = client_type == 'Bonanza' ? "https://dashboard.bonanzaonline.com/" : "https://dashboard.bigul.co/";
  //app
  // const baseUrl = client_type == 'Bonanza' ? "https://dashboard-bonanza.bigulapp.in/" : "https://dashboard.bigulapp.in/";
  const result = url?.replace(baseUrl, "");

  let urlParams = new URLSearchParams(url1?.split('?')[1]);
  let redirect = urlParams.get('tab');
  console.log(redirect, 'tab');
  useEffect(() => {
    // Check if the profile data contains a name, or adjust this check based on your requirements
    if (!isEmpty(profileDetails) && profileDetails[0]?.FIBSACCT) {
      setLoading(false);  // Set loading to false when profile details are ready
    }
  }, [profileDetails]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post("https://bigul.co/api/buyback-get-token");
      if (response?.status) {
        try {

          const headers = {
            "token": response?.token,// Example of an Authorization header
            "Content-Type": "application/json",         // Example of Content-Type
          };
          const params = {
            client_code: client_code,
          };

          // Make GET request using Axios
          const response1 = await axios.get("https://bigul.co/api/check-client-optout-status", {
            headers: headers,
            params: params,
          });

          if (response1) {
            setOptOut(response?.token)
            setOptOut1(response1?.status)
          }

        } catch (err) {

        }
      }



    };

    fetchData();



  }, []);

  if (result == 'profile') {
    localStorage.setItem('redirect', redirect);
  }

  if (client_id_check == undefined || client_id_check == 'NULL' || client_id_check == "") {
    var ii = 0;
  }
  else {
    // live
    return <Navigate to={{
      pathname: url == null ? '/dashboard' : '/' + result
    }}

    //uat
    // return <Navigate to={{
    //   pathname: client_type === "Bigul" ? url == null ? '/dashboard' : '/' + result : '/family'
    // }}
    />
  }
  if (loading) {
    return (
      <Loader class={"mt-5"} />
    )
  }
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <BreadCrumb
            title="Overview"
            titleMain={`Welcome, ${!isEmpty(profileDetails[0]) ? profileDetails[0].FIBSACCT.split(' ')[0] : ""}!`}
            titleSecondary={`Here's what's happening with your account today.`}
            pageTitle="Dashboard"
            to='/dashboard'
          />
          <Row className="mt-3 mb-5 margin-mobile-row2">
            <Widgets />
          </Row>
          <Row className="spacing-remove mb-5 margin-mobile-row">
            {/* <MyPortfolio /> */}
            <MyPortfolio1 profileDate={!isEmpty(profileDetails[0]) ? profileDetails[0] : {}} generatedKey={generatedKey} encryptKey={encryptKey} />
            <MarginOverview generatedKey={generatedKey} encryptKey={encryptKey} optOut ={optOut} optOut1 ={optOut1}  />
            <Highlights />
          </Row>
          {/* <Row className="">
            <MyInvestedAlgos />
            <SuggestedAlgos />
          </Row> */}
        </Container>
      </div>
      {/* <div
        className="page-content"
        style={{ marginTop: "0", paddingTop: "0", paddingBottom: "0" }}
      >
        <ActiveFeatures />
      </div> */}
      {
        client_type == "Bigul" &&
        <Container fluid className="mb-5">
          <Row className="px-2 my-4 margin-mobile-row-non margin-tab-row-non">
            <ActiveFeatures1 />
          </Row>
          <Row className="spacing-bottom-m my-3 margin-top-remove">
            <AddonsSlider />
          </Row>
          <Row className="mb-4 margin-mobile-row-non">
            <SuggestedAlgos1 />

          </Row>
          <Row className="">
            <ELearningSlider />
          </Row>
        </Container>
      }

    </React.Fragment>
  );
};

export default BigulDashboard;
